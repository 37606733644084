import DipylonLogo from "../assets/dipylonlogo.jpg";
import Typography from '@mui/material/Typography'; 
import Container from '@mui/material/Container'; 
import Divider from '@mui/material/Divider'; 
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';  
import Stack from '@mui/material/Stack';    
import Box from '@mui/material/Box';    

const Footer = () => {
  return (
    <footer>
      <Container sx={{textAlign:'center', margin:'0 auto', verticalAlign:'middle', height:'100px', paddingTop:'10px'}}>
        <Typography component='div' variant='body2' >scientific supervision <a href="https://www.ha.uth.gr/index.php?page=faculty.display&a=gialolos" target="_blank" rel="noreferrer"> Yannis Lolos</a>, <a href="https://www.ha.uth.gr/index.php" target="_blank" rel="noreferrer">Department of History, Archaeology and Social Anthropology, University of Thessaly </a>   </Typography> 
        <Divider sx={{margin:'10px auto', width:'140px', color:'darkgrey', borderColor:'0,0,0,1'}} variant="middle" />
        <Typography component='div' variant='body2' ><b>Copyright and Data Publication Statement : </b>This platform contains unpublished data, which are intellectual property of the Sikyon Project. Therefore, publication of any of these data in any form (printed or digital) is not allowed without previous consent by their creators. For any relevant inquiry <a href="/copyrights" > please contact us</a>  </Typography> 
        <Divider sx={{margin:'10px auto', width:'140px', color:'darkgrey', borderColor:'0,0,0,1'}} variant="middle" />
        <Typography component='div' variant='body2' >© 2023 <a href="https://dipylon.org/en/" target="_blank" rel="noreferrer"> Dipylon</a> , developed by <a href="https://www.linkedin.com/in/spirosmousouris/" target="_blank" rel="noreferrer">  Spiros Mousouris</a></Typography> 
      
        <a href="https://dipylon.org/en/" target="_blank" rel="noreferrer">
          <img
            src={DipylonLogo}
            alt="Dipylon"
            style={{    
              width: '188px',
              height: '47px',
              marginTop: '5px',
              backgroundColor: 'white',
              padding: '3px'}}
          />
        </a> 
        <Divider sx={{margin:'10px auto', width:'140px',  color:'darkgrey', borderColor:'0,0,0,1'}} variant="middle" />
        <Box component='div' sx={{margin:'0 auto', width:'140px'}}>
          <Stack direction="row" spacing={2} sx={{textAlign:'center', margin:'0 auto'}}>
            <Typography variant='body2' ><a href="https://www.facebook.com/DipylonAthens/" target="_blank" rel="noreferrer"> <FacebookIcon style={{ color: 'grey' }}/> </a> </Typography> 
            <Typography variant='body2' ><a href="https://twitter.com/DipylonSociety" target="_blank" rel="noreferrer"> <TwitterIcon style={{ color: 'grey' }}/> </a> </Typography> 
            <Typography variant='body2' ><a href="https://www.instagram.com/dipylon_athens/" target="_blank" rel="noreferrer"> <InstagramIcon style={{ color: 'grey' }}/> </a> </Typography> 
            <Typography variant='body2' ><a href="https://www.linkedin.com/company/74948280/" target="_blank" rel="noreferrer"> <LinkedInIcon style={{ color: 'grey' }}/> </a> </Typography>
          </Stack> 
        </Box>

        <Divider sx={{margin:'10px auto', width:'140px',  color:'darkgrey', borderColor:'0,0,0,1'}} variant="middle" />

        <Box component='div' sx={{margin:'0 auto', width:'150px'}}> 
          <Stack direction="row" spacing={2} sx={{textAlign:'center', margin:'0 auto'}}>
            <Typography component='div' variant='body2' > <a href="/policy" > Policy</a> </Typography> 
            <Typography component='div' variant='body2' > <a href="/terms-of-use" > Terms Of Use</a> </Typography> 
          </Stack>
        </Box>

      </Container>
    </footer>
  )
}

export default Footer