import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";  

let FEATURE_URL = 'api/map'
if (process.env.REACT_APP_ENV === 'dev') {
   FEATURE_URL = 'http://localhost:3500/map'
} 

//status : idle, loading, succeeded, failed
const initialState = {
    info:'a',
    status:'idle',
    error:null
} 

export const fetchFeatureInfoById = createAsyncThunk('feature/fetchInfoById', async (originalData) => { 
    const response = await axios.get(FEATURE_URL+'/feature/'+originalData.id+'/'+originalData.category) 
    return response.data
})

export const featuresSlice = createSlice({
    name: 'features',
    initialState,
    reducers: { 
        featureInfoById:{
            reducer(state, action){
                //do something with the id 
                console.log(' ');
            },
            prepare(category, id){
                return{
                    payload:{
                        category,
                        id
                    }
                }
            }
        }
    },
    extraReducers(builder){
        builder
        .addCase(fetchFeatureInfoById.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(fetchFeatureInfoById.rejected, (state, action) => {
            state.status = 'failed';  
        })
        .addCase(fetchFeatureInfoById.fulfilled, (state, action) => { 
            state.status = 'succeeded';
            state.info = action.payload; 
        })
    }
  }) 
   
  export const featureInfo = (state) => state.features.info;
  export const featureError = (state) => state.features.error;
  export const featureStatus = (state) => state.features.status;
  export const {featureInfoById} = featuresSlice.actions
  export default featuresSlice.reducer