import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals'; 
import store from "./redux/store";
import {Provider} from "react-redux"; 
import 'mapbox-gl/dist/mapbox-gl.css';  

 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(   
  <React.StrictMode> 
    <Provider store={store}>
      <App />  
    </Provider> 
  </React.StrictMode>
);  
reportWebVitals();


