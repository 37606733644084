 import { useEffect, useState, useRef } from 'react';    
//  import Popper from '@mui/material/Popper';
//  import Box from '@mui/material/Box';
//  import Button from '@mui/material/Button';
 import './App.css';
 import Header from './components/Header';
 import Footer from './components/Footer';
 import About from './components/About'; 
 import Copyrights from './components/Copyrights'; 
 import Home from './components/Home';   
 import Map from './components/Map';    
 import Policy from './components/Policy';    
 import Terms from './components/Terms';    
 import {BrowserRouter as Router , Route, Routes} from 'react-router-dom'
 import { createTheme, ThemeProvider } from '@mui/material/styles';
 import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
 import ReactGA from "react-ga4";

function App() {  
  
  const [showCookiesPopUp, setShowCookiesPopUp] = useState(false);
  const anchorCookiesHook = useRef(null);

  
  const handleAcceptCookie = () => {
    ReactGA.initialize("G-MPSSF6F8TY");
    localStorage.setItem("acceptCookies", 'a'); 
  };

  const handleDeclineCookie = () => {
      localStorage.setItem("acceptCookies", 'r'); 
      document.cookie = "_ga; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "_mkto_trk; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "mkjs_group_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "mkjs%3Atest; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "mkjs%3Acookies; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "<REMOVED>; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "mkjs_user_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "_gid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "rack.session; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "_gh_sess; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "drift_aid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "drift_campaign_refresh; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "driftt_aid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "_uetvid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "_fbp; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "_uetsid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "ajs_user_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "AnalyticsSyncHistory; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "lidc; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "li_sugr; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "UserMatchHistory; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "bcookie; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "lang; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "li_gc; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "drift_aid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "drift_campaign_refresh; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "driftt_aid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "personalization_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "lang; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "referrer_url; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "twitch.lohp.countryCode; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "unique_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "unique_id_durable; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "api_token; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "experiment_overrides; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "server_session_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "MUID; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "YSC; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "CONSENT; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "VISITOR_INFO1_LIVE; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "bscookie; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      Object.keys(localStorage).forEach(key => {
        if (key.startsWith('ga_') || key.startsWith('gid_') || key.startsWith('_ga') || key.startsWith('_gid') ) {
          localStorage.removeItem(key);
        }
      });
  };

  // const cookiesBtn = (ar) => {
  //   setShowCookiesPopUp(false)
  //   if (ar === 'a') {
  //     localStorage.setItem("acceptCookies", 'a'); 
  //     ReactGA.initialize("G-MPSSF6F8TY");
  //   }
  //   else{
  //     localStorage.setItem("acceptCookies", 'r');
  //     document.cookie = "_ga; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "_mkto_trk; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "mkjs_group_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "mkjs%3Atest; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "mkjs%3Acookies; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "<REMOVED>; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "mkjs_user_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "_gid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "rack.session; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "_gh_sess; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "drift_aid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "drift_campaign_refresh; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "driftt_aid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "_uetvid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "_fbp; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "_uetsid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "ajs_user_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "AnalyticsSyncHistory; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "lidc; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "li_sugr; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "UserMatchHistory; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "bcookie; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "lang; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "li_gc; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "drift_aid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "drift_campaign_refresh; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "driftt_aid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "personalization_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "lang; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "referrer_url; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "twitch.lohp.countryCode; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "unique_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     document.cookie = "unique_id_durable; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

  //     Object.keys(localStorage).forEach(key => {
  //       if (key.startsWith('ga_') || key.startsWith('gid_') || key.startsWith('_ga') || key.startsWith('_gid') ) {
  //         localStorage.removeItem(key);
  //       }
  //     });

  //   }
  // }; 

  useEffect(() => { 
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
    if (isConsent !== "true") {
      handleDeclineCookie();
    }
    // const acceptCookiesValue = localStorage.getItem("acceptCookies"); 
    // if (acceptCookiesValue !== 'a' && acceptCookiesValue !== 'r') {
    //   setShowCookiesPopUp(true)
    // } 
    // if (acceptCookiesValue === 'a') {
    //   ReactGA.initialize("G-MPSSF6F8TY");
    // } 
    // if (acceptCookiesValue === 'r') {
    //   document.cookie = "_ga; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "_mkto_trk; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "mkjs_group_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "mkjs%3Atest; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "mkjs%3Acookies; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "<REMOVED>; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "mkjs_user_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "_gid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "rack.session; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "_gh_sess; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "drift_aid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "drift_campaign_refresh; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "driftt_aid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "_uetvid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "_fbp; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "_uetsid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "ajs_user_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "AnalyticsSyncHistory; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "lidc; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "li_sugr; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "UserMatchHistory; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "bcookie; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "lang; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "li_gc; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "drift_aid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "drift_campaign_refresh; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "driftt_aid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "personalization_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "lang; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "referrer_url; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "twitch.lohp.countryCode; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "unique_id; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //   document.cookie = "unique_id_durable; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    //   Object.keys(localStorage).forEach(key => {
    //     if (key.startsWith('ga_') || key.startsWith('gid_') || key.startsWith('_ga') || key.startsWith('_gid') ) {
    //       localStorage.removeItem(key);
    //     }
    //   });

    // } 
  }, []); 

  const theme = createTheme({
    palette: {
      primary: {
        light: '#1a2299',
        main: '#000',
        dark: '#f4f4f4',
        contrastText: '#fff',
      },
      secondary: {
        light: '#991700',
        main: '#fefefe',
        dark: '#f3f3',
        contrastText: '#000',
      },
    },
  });  
  

  return (  
    <div>
        <Router>  
          <ThemeProvider theme={theme}>
            <Header title='sikyona'  /> 
            {/* <div className="general-container"> */}
                <Routes>
                  <Route path='/' element={<Home/>} />   
                  <Route path='/about' element={<About/>} /> 
                  <Route path='/map' element={<Map/>} />  
                  <Route path='/policy' element={<Policy/>} />  
                  <Route path='/terms-of-use' element={<Terms/>} />  
                  <Route path='/copyrights' element={<Copyrights/>} />  
                </Routes>   
              {/* </div>                         */}
            <Footer />   
          </ThemeProvider>
        </Router>  

      <CookieConsent 
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        buttonText='Accept'
        declineButtonText='Decline'
      >
        This website uses cookies for its efficient operation and to improve your navigation. By continuing to browse, you accept their use, in accordance with our <a style={{color:'lightgrey'}} href="/policy">cookie policy</a>
      </CookieConsent>


      {/* <Popper 
        open={showCookiesPopUp}  

        style={{
          position:'absolute',
          bottom:'0px', 
          top:'',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width :'calc(100% - 20px)'
        }}
      >

        <Box id='cookiesContainer' sx={{ border: 1, p: 1, bgcolor: 'background.paper', width:'100%', margin:'0 auto', display:'block', textAlign:'center' }}> 
          <div id='cookiesContainerInside'>
            This website uses cookies for its efficient operation and to improve your navigation. By continuing to browse, you accept their use, in accordance with our <a href="/policy">cookie policy</a>
            <Button size='small' variant="outlined" onClick={()=>{cookiesBtn('r')}} color="primary">
              Reject
            </Button> 
            <Button size='small' variant="outlined" onClick={()=>{cookiesBtn('a')}} color="primary">
              Accept
            </Button> 
          </div>
        </Box>
      </Popper>

      <div
        ref={anchorCookiesHook} 
        style={{
          position:'absolute',
          bottom: '0',
          right:'50%', 
          width: '10px',
          height: '20px'
        }}
        id='cookiesHook'
      > 
      </div>  */}
    </div>    
  );
}

export default App;
