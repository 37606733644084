import {configureStore} from "@reduxjs/toolkit"; 
import search from './search'
import features from './features'
import contact from './contact'

export default configureStore({
    reducer:{ 
        search,
        features,
        contact
    }
})
