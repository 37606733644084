import { useEffect, useState, useRef  } from 'react';      
import Typography from '@mui/material/Typography'; 
import Grid from '@mui/material/Grid'; 
import Paper from '@mui/material/Paper'; 
import Box from '@mui/material/Box';  
import { TextField, Button, Stack } from '@mui/material';
import ReCAPTCHA from "react-google-recaptcha"
import store from '../redux/store';  
import {useSelector,useDispatch} from "react-redux"; 
import { fetchCaptcha, fetchForm } from "../redux/contact";

const Copyrights = () => {
  
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [inquiry, setInquiry] = useState('')
  const [mailMessage, setMailMessage] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(true)
  const dispatch = useDispatch() ;      
  
  const captchaRef = useRef(null)

  function handleSubmit(event) {
    event.preventDefault();  
    setMailMessage('')
    setMailMessage('Sending message')
    dispatch(fetchForm({firstName, lastName, email, subject, inquiry}))
    .then((e)=>{
      const state = store.getState() 
      console.log('form front ', state.contact.form.info, state.contact.form.error, state.contact.form.status);
      if (state.contact.form.status==='succeeded') {
        if (state.contact.form.info.success===true) {
          setDisableSubmit(true)
          setFirstName('')
          setLastName('')
          setEmail('')
          setSubject('')
          setInquiry('')
          setMailMessage('Thank you. We received your message and we will contact you.')
        }else{
          setMailMessage('An error occured. Please try again later.')
          setDisableSubmit(true) 
        }
      }
      
    })
    captchaRef.current.reset(); 
    setDisableSubmit(true)
}

function handleCaptchaChange(event) {
  setMailMessage('')
  dispatch(fetchCaptcha({captcha:captchaRef.current.getValue()}))
  .then((e)=>{
    
    const state = store.getState()  
    console.log('capactah fromnt ', state.contact.captcha);
    if (state.contact.captcha.info.success===true) {
      setDisableSubmit(false)
    }else{
      setDisableSubmit(true)
    }
  })
}

  useEffect(() => { 
    document.title = 'Copyrights , Sikyon excavation'; 
  }, []);
  return (
    <div className='general-container' style={{height:'125vh'}} >
      <Typography component='div' variant='h4' className='aboutheader' >Copyright and Data Publication</Typography>  
      <Grid container spacing={2} id='aboutcontainer' >
        <Grid item xs={12}>
          <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}>
            <Typography component='div' variant='h5' className='aboutheader' >Copyright and Data Publication Statement</Typography> 
            <Box sx={{textAlign:'center'}}> 
              <p>This platform contains unpublished data, which are intellectual property of the Sikyon Project. </p>
              <p>Therefore, publication of any of these data in any form (printed or digital) is not allowed without previous consent by their creators. </p>
              <p>For any relevant inquiry please use the form below to contact us </p>
            </Box>
          </Paper>
        </Grid>  


        <Grid item xs={12}>

          <form onSubmit={handleSubmit}>
            <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
              <TextField
                  type="text"
                  variant='outlined' 
                  label="First Name"
                  onChange={e => {setFirstName(e.target.value); setMailMessage('')}}
                  onFocus={e => {setMailMessage('')}}
                  value={firstName}
                  fullWidth
                  required
              />
              <TextField
                  type="text"
                  variant='outlined' 
                  label="Last Name"
                  onChange={e => {setLastName(e.target.value); setMailMessage('')}}
                  onFocus={e => {setMailMessage('')}}
                  value={lastName}
                  fullWidth
                  required
              />
            </Stack>
            <Stack spacing={2} direction="row" sx={{marginBottom: '0px'}}>
              <TextField
                  type="email"
                  variant='outlined' 
                  label="Email"
                  onChange={e => {setEmail(e.target.value); setMailMessage('')}}
                  onFocus={e => {setMailMessage('')}}
                  value={email}
                  fullWidth
                  required
                  sx={{mb: 4}}
              /> 
              <TextField
                  type="text"
                  variant='outlined' 
                  label="Subject"
                  onChange={e => {setSubject(e.target.value); setMailMessage('')}}
                  onFocus={e => {setMailMessage('')}}
                  value={subject}
                  fullWidth
                  required
                  sx={{mb: 4}}
              /> 
            </Stack>
            <TextField
                type="text"
                variant='outlined' 
                label="Inquiry"
                onChange={e => {setInquiry(e.target.value) ; setMailMessage('')}}
                onFocus={e => {setMailMessage('')}}
                value={inquiry}
                fullWidth
                required
                multiline
                rows={4}
                maxRows={8}
                sx={{mb: 4}}
            /> 
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={captchaRef}
              onChange={handleCaptchaChange}
            />
            <Button disabled={disableSubmit} variant="outlined"  type="submit" sx={{marginTop:"32px;"}}>Submit</Button>
          </form>

          <div  >{mailMessage}</div>  

        </Grid>

      </Grid> 

    </div>
  )
}

export default Copyrights
