import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";  

let FEATURE_URL = 'api/contact'
if (process.env.REACT_APP_ENV === 'dev') {
   FEATURE_URL = 'http://localhost:3500/contact'
} 

//status : idle, loading, succeeded, failed
const initialState = {
    captcha:{
      info:'a',
      status:'idle',
      error:'null'
    },
    form:{
      info:'a',
      status:'idle',
      error:'null'
    }
} 

export const fetchCaptcha = createAsyncThunk('contact/fetchCaptcha', async (searchParams) => { 
  const headers = {
      "Content-Type": "text/json"
  }; 
  console.log('back captch ',searchParams);
  const response = await axios.post(FEATURE_URL+'/captcha', {...searchParams});  
  return response.data
})

export const fetchForm = createAsyncThunk('contact/fetchForm', async (searchParams) => { 
    const headers = {
        "Content-Type": "text/json"
    }; 
    console.log('back form ',searchParams);
    const response = await axios.post(FEATURE_URL+'/form', {...searchParams});  
    return response.data
  }) 

export const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: { 
        getCaptchaResults:{
            reducer(state, action){
                //do something with the id 
                console.log('');
            },
            // prepare(category, id){
            //     return{
            //         payload:{
            //             category,
            //             id
            //         }
            //     }
            // }
        }
    },
    extraReducers(builder){
        builder
        .addCase(fetchCaptcha.pending, (state, action) => {
            state.captcha.status = 'loading'
        })
        .addCase(fetchCaptcha.rejected, (state, action) => {
            state.captcha.status = 'failed';  
        })
        .addCase(fetchCaptcha.fulfilled, (state, action) => { 
            state.captcha.status = 'succeeded';
            state.captcha.info = action.payload; 
        })
        .addCase(fetchForm.pending, (state, action) => {
          state.form.status = 'loading'
        })
        .addCase(fetchForm.rejected, (state, action) => {
            state.form.status = 'failed';  
        })
        .addCase(fetchForm.fulfilled, (state, action) => { 
            state.form.status = 'succeeded';
            state.form.info = action.payload; 
        })
    }
  }) 
   
  export const fetchCaptchaInfo = (state) => state.contact.captcha.info;
  export const fetchCaptchaError = (state) => state.contact.captcha.error;
  export const fetchCaptchaStatus = (state) => state.contact.captcha.status;

  export const fetchFormInfo = (state) => state.contact.form.info;
  export const fetchFormError = (state) => state.contact.form.error;
  export const fetchFormStatus = (state) => state.contact.form.status;

  export const {getCaptchaResults} = contactSlice.actions
  export default contactSlice.reducer