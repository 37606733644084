import { useEffect } from 'react';    
import Typography from '@mui/material/Typography';  
import Box from '@mui/material/Box';  
// import Link from '@mui/material/Link';  
import bckimg from "../assets/back.jpg";
import {Link} from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Home = () => { 
  useEffect(() => {
    document.title = 'Home , Sikyon excavation';
  }, []);
  return (
    <div className="general-container">
      <Box 
        style={{
        backgroundImage: `url(${bckimg})`,
        backgroundSize: "cover",
        height: "100vh",
        color: "#fff"
      }}>
        <Typography component='div' variant='h1' id='homeTitle' sx={{position:'absolute', bottom:'190px', right:'50px', padding:'5px', backgroundColor:'rgba(0, 0, 0, 0.3)'}} > 
          <a style={{color:'#fff', textDecoration:'none'}} href="http://extras.ha.uth.gr/sikyon/" target="_blank" rel="noreferrer"> Sikyon Excavation</a> 
        </Typography> 
        <Typography component='div' variant='h3' id='homeSubtitle' sx={{position:'absolute', bottom:'110px', right:'50px' , padding:'5px', backgroundColor:'rgba(0, 0, 0, 0.3)'}} > 
          <Link to='/map' style={{color:'#fff', textDecoration:'none'}} >explore the map <ChevronRightIcon sx={{transform:'scale(2)'}}/></Link>
        </Typography> 
      </Box> 

      
    </div>
  )
}

export default Home
