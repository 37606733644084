import { useSearchParams } from 'react-router-dom';
import store from '../redux/store';
import ExcavationInfo from './featuresInfo/ExcavationInfo';
import PartInfo from './featuresInfo/PartInfo';
import ContextInfo from './featuresInfo/ContextInfo';
import {useSelector,useDispatch} from "react-redux";
import { fetchFeatureInfoById } from "../redux/features";
import { searchCategoriesInfo , fetchSearchCategories, fetchSearchResults, } from "../redux/search";
import { useRef, useEffect, useState, useCallback } from 'react';
import * as ReactDOM from 'react-dom/client';
import * as turf from "@turf/turf";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import LayersIcon from '@mui/icons-material/Layers';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import Drawer from '@mui/material/Drawer';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import PlaceIcon from '@mui/icons-material/Place';
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Popper from '@mui/material/Popper';
import Backdrop from '@mui/material/Backdrop';
import { display } from '@mui/system';
import mapboxgl from 'mapbox-gl';
import Exfimage from '../assets/exf.png';
import Expimage from '../assets/exp.png';
import Paimage from '../assets/pa.png';
import Coimage from '../assets/co.png';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import StraightenIcon from '@mui/icons-material/Straighten';
import TimelineIcon from '@mui/icons-material/Timeline';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Map() {
  const [valueSearchTab, setValueSearchTab] = useState(0);
  const handleChangeSearchTab = (e,newValue) => {
    setValueSearchTab(newValue);
  };

  let globalCU
  let globalMarker = 'empty'
  let dbClick = false

  let idFitBounds;
  let sourceFitBounds;
  let clickFeatureFromListFitBounds = false

  const mapContainer = useRef(null);
  const map = useRef(null);
  const mapDraw = useRef(null);

  const anchorRefExPop = useRef(null);
  const anchorRefExInfo = useRef(null);

  // const anchorLegend = useRef(null)
  const anchorMeasure = useRef(null)

  const dispatch = useDispatch() ;

  const searchCategoriesInfoSelector = useSelector(searchCategoriesInfo)

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAngle, setAnchorElAngle] = useState(null);
  const [anchorElCopyrights, setAnchorElCopyrights] = useState(null);
  const [anchorElLegend, setAnchorElLegend] = useState(null);

  const [checkedExcavation, setCheckedExcavation] = useState(true);
  const [checkedPart, setCheckedPart] = useState(true);
  const [checkedContext, setCheckedContext] = useState(true);
  const [excavationSlider, setExcavationSlider] = useState(30);
  const [partSlider, setPartSlider] = useState(40);
  const [contextSlider, setContextSlider] = useState(50);
  const open = Boolean(anchorEl);
  const openAngle = Boolean(anchorElAngle);
  const openCopyrights = Boolean(anchorElCopyrights);
  const openLegend = Boolean(anchorElLegend);

  const [excavationsList, setExcavationsList] = useState([]);
  const [partsList, setPartsList] = useState([]);
  const [contextsList, setContextsList] = useState([]);

  const [excavationsResultsList, setExcavationsResultsList] = useState([]);
  const [partsResultsList, setPartsResultsList] = useState([]);
  const [contextsResultsList, setContextsResultsList] = useState([]);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [resultsMessageVisible, setResultsMessageVisible] = useState(false);
  const [resultsMessage, setResultsMessage] = useState('');

  const [layerDrawer, setLayerDrawer] = useState(false);
  const [resultsDrawer, setResultsDrawer] = useState(false);
  const [gotoDrawer, setGotoDrawer] = useState(false);
  const [highlightExArrowId, setHighlightExArrowId] = useState(false);
  const [highlightPaArrowId, setHighlightPaArrowId] = useState(false);
  const [featuresInfoDrawer, setFeaturesInfoDrawer] = useState(false);
  const [stratigraphyInfoDrawer, setStratigraphyInfoDrawer] = useState(false);

  const [highlightExSearchArrowId, setHighlightExSearchArrowId] = useState(false);
  const [highlightPaSearchArrowId, setHighlightPaSearchArrowId] = useState(false);

  const [valueTab, setValueTab] = useState(0);

  const [buildingsAutocompleteVal, setBuildingsAutocompleteVal]=useState()
  const [contextsAutocompleteVal, setContextsAutocompleteVal]=useState()


  // const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [clickedFeatures, setClickedFeatures] = useState({
    excavations:false,
    parts:false,
    contexts:false
  });

  const [searchParams, setSearchParams] = useState({
    buildings:false,
    periods:false,
    contexts:false
  });

  const [resultFeatures, setResultFeatures] = useState({
    excavations:false,
    parts:false,
    contexts:false
  });

  const [urlParams, setUrlParams] = useSearchParams();

  const [openExPop, setOpenExPop] = useState(false);
  const [dontShowAgainExPop, setDontShowAgainExPop] = useState(false);

  const [openExInfo, setOpenExInfo] = useState(false);
  // const [openLegend, setOpenLegend] = useState(false);

  const [openMeasure, setOpenMeasure] = useState(false);
  const [measurePolygon, setMeasurePolygon] = useState('');
  const [measureLine, setMeasureLine] = useState('');
  const [degreesSlider, setDegreesSlider] = useState('');

  const [exInfoText, setExInfoText] = useState('');

  const [openBackDrop, setOpenBackDrop] = useState(true);

  const [acceptCookiesValue, setAcceptCookiesValue] = useState('');

  const [positionMarker, setPositionMarker] = useState({marker:'empty'});

  const [extrudeAllClicked, setExtrudeAllClicked] = useState(false);
  const [extrudeAllClickedSearch, setExtrudeAllClickedSearch] = useState(false);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const handleChangeTab = (e,newValue) => {
    setValueTab(newValue);
  };

  const toggleLayerDrawer = (setLayerDrawer) => {
    setLayerDrawer(!layerDrawer);
  };

  const toggleResultsDrawer = (setResultsDrawer) => {
    setResultsDrawer(!resultsDrawer);
  };

  const toggleGotoDrawer = (setGotoDrawer) => {
    setGotoDrawer(!gotoDrawer);
  };

  const toggleFeaturesInfoDrawer = (setFeaturesInfoDrawer) => {
    setFeaturesInfoDrawer(!featuresInfoDrawer);
  };

  const toggleStratigraphyInfoDrawer = (setStratigraphyInfoDrawer) => {
    setStratigraphyInfoDrawer(!stratigraphyInfoDrawer);
  };

  const handleClick = (event, setAnchorEl) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAngleClick = (event, setAnchorElAngle) => {
    setAnchorElAngle(event.currentTarget);
  };

  const handleCopyrightsClick = (event, setAnchorElCopyrights) => {
    setAnchorElCopyrights(event.currentTarget);
  };

  const handleLegendClick = (event, setAnchorElLegend) => {
    setAnchorElLegend(event.currentTarget);
  };

  const handleClose = (setAnchorEl) => {
    setAnchorEl(null);
  };

  const handleCloseAngle = (setAnchorElAngle) => {
    setAnchorElAngle(null);
  };

  const handleCloseCopyrights = (setAnchorElCopyrights) => {
    setAnchorElCopyrights(null);
  };

  const handleCloseLegend = (setAnchorElLegend) => {
    setAnchorElLegend(null);
  };

  // const toggleLegend = () => {
  //   setOpenLegend(!openLegend)
  // };

  const toggleMeasure = () => {
    setOpenMeasure(!openMeasure)
  };

  const reCenterMap = (map) => {
    map.current.flyTo({
      center: [22.7127, 37.9838],
      zoom: 16.40,
      duration:6000,
      pitch:0,
      bearing:0  ,
      offset:[0,0]
    });
  };

  const handleClickOpenExPop = () => {
    setOpenExPop(true);
  };

  const handleCloseExPop = () => {
    setOpenExPop(false);
  };

  const handleCheckboxChangeExPop = (event) => {
    setDontShowAgainExPop(event.target.checked);
  };

  const handleLayerVisibleChange = (event, setCheckedExcavation, setCheckedPart, setCheckedContext, map) => {
    let visible ;
    let layer; let labels;let line;
    event.target.checked ? visible = 'visible' : visible = 'none'

    if (event.target.id === 'excavation') {
      // visible === 'visible' ? excavationLayerGlobalActive = true : excavationLayerGlobalActive = false
      setCheckedExcavation(event.target.checked);
      layer = 'excavations-layer';labels = 'excavations-labels';line = 'excavations-line';
    }
    else if (event.target.id === 'part'){
      // visible === 'visible' ? partLayerGlobalActive = true : partLayerGlobalActive = false
      setCheckedPart(event.target.checked);
      layer = 'excavation_parts-layer';labels = 'excavation_parts-labels';line='excavation_parts-line'
    }
    else if(event.target.id === 'context'){
      // visible === 'visible' ? contextLayerGlobalActive = true : contextLayerGlobalActive = false
      setCheckedContext(event.target.checked);
      layer = 'contexts-layer';labels = 'contexts-labels';line='contexts-line'
    }
    map.current.setLayoutProperty(layer, 'visibility', visible);
    map.current.setLayoutProperty(labels, 'visibility', visible);
    map.current.setLayoutProperty(line, 'visibility', visible);
  };

  //functions like ths that are getting called often and change the state, do not have to be in useEffect, because they will also have to be in the dependencies array
  //so  they will re-initialize the map all the time - that is unwanted because it will slow the app down
  // when you call the function outside of the useEffect, you create a closure that captures the current state of the component. If the component re-renders, the closure will still have a reference to the previous state of the component, which can cause memory leaks and unexpected behavior.
  // One way to avoid this is by passing any required variables as arguments to the function, rather than referencing them directly. This way, the function will always use the latest state of the variables.
  const handleLayerSlider = (event, map, setExcavationSlider, setPartSlider, setContextSlider) => {
    let layer; let labels; let line;
    let opacityValue = parseInt(event.target.value, 10) / 100 ;

    if (event.target.name === 'excavation') {
      setExcavationSlider(event.target.value)
      // event.target.value === 0 ? excavationLayerGlobalActive = false : excavationLayerGlobalActive = true
      layer = 'excavations-layer';labels = 'excavations-labels'; line='excavations-line';
    }
    else if (event.target.name === 'part'){
      setPartSlider(event.target.value)
      // event.target.value === 0 ? partLayerGlobalActive = false : partLayerGlobalActive = true
      layer = 'excavation_parts-layer';labels = 'excavation_parts-labels';line='excavation_parts-line';
    }
    else if(event.target.name === 'context'){
      // event.target.value === 0 ? contextLayerGlobalActive = false : contextLayerGlobalActive = true
      setContextSlider(event.target.value)
      layer = 'contexts-layer';labels = 'contexts-labels'; line='contexts-line';
    }
    setAllLayersOpacity(layer, labels, line, opacityValue, map)
  }

  const handleDegreeSlider = (event, map, setDegreesSlider) => {
    setDegreesSlider(event.target.value)
    map.current.flyTo({
      pitch: event.target.value
    });
  }

  const setAllLayersOpacity = (layer, labels, line, opacityValue, map) =>{
    map.current.setPaintProperty(layer, 'fill-opacity', opacityValue);
    map.current.setPaintProperty(labels, 'text-opacity', opacityValue);
    map.current.setPaintProperty(line, 'line-opacity', opacityValue);
  }

  const setClickStateFeatures = (sourceLayer, source, id, map) => {
    if (!sourceLayer) {
      let featuresCo = map.current.queryRenderedFeatures({layers:['contexts-layer']});
      let f = featuresCo.filter((e) =>
        e.properties.id === id
      )
      map.current.setFeatureState({source, id: f[0].id}, {hover: true});
    }
    else{
      map.current.setFeatureState({sourceLayer, source, id: id}, {hover: true});
    }
  }//setClickStateFeatures

  const setClickStateFeaturesPartInfo = (id) => { 
    console.log('setClickStateFeaturesPartInfo id ', id);
    setClickStateFeatures(false, 'contexts-source', id, map)
  }

  const unSetClickStateFeaturesPartInfo = (id) => { 
    console.log('unSetClickStateFeaturesPartInfo id ', id);
    unSetClickStateFeatures(false, 'contexts-source', id, map)
  }

  const clickFeatureFromListPartInfo  = (id, name) => { 
    console.log('clickFeatureFromListPartInfo id ',id, name);
    clickFeatureFromList('contexts-source', id, 'co', true, 'pa', name)
  }


  const unSetClickStateFeatures = (sourceLayer, source, id, map) => {
    if (!sourceLayer) {
      let featuresCo = map.current.queryRenderedFeatures({layers:['contexts-layer']});
      let f = featuresCo.filter((e) =>
        e.properties.id === id
      )
      map.current.setFeatureState({source, id: f[0].id}, {hover: false});
    }
    else{
      map.current.setFeatureState({sourceLayer, source, id: id}, {hover: false});
    }
  }//unSetClickStateFeatures


  const prepareExtrudeAll = (contexts, setExtrudeAllClicked) => {
    setExtrudeAllClicked(!extrudeAllClicked)
    if (extrudeAllClicked) {
      map.current.setFilter('contexts-extrude', ['==',['id'] , 0] );
      // map.current.setBearing(0)
      // map.current.setPitch(0)
      map.current.flyTo({
        duration:3000,
        pitch:0,
        bearing:0  ,
        offset:[0,0]
      });
      return
    }
    let nos = [];
    contexts.forEach(e => {
      nos.push(e.properties.no)
    });
    extrudeContexts(nos)
  }//prepareExtrudeAll

  const prepareExtrudeAllSearch = (contexts, setExtrudeAllClickedSearch) => {
    setExtrudeAllClickedSearch(!extrudeAllClickedSearch)
    if (extrudeAllClickedSearch) {
      map.current.setFilter('contexts-extrude', ['==',['id'] , 0] );
      // map.current.setBearing(0)
      // map.current.setPitch(0)
      map.current.flyTo({
        duration:3000,
        pitch:0,
        bearing:0  ,
        offset:[0,0]
      });
      return
    }
    let nos = [];
    contexts.forEach(e => {
      nos.push(e.properties.no)
    });
    extrudeContexts(nos)
  }//prepareExtrudeAllSearch

  const prepareExtrudeContext = (nos, extrudeClicked) => {
    if (extrudeClicked) {
      map.current.setFilter('contexts-extrude', ['==',['id'] , 0] );
      map.current.flyTo({
        duration:3000,
        pitch:0,
        bearing:0  ,
        offset:[0,0]
      });
      return
    }
    extrudeContexts(nos)
  }//prepareExtrudeContext

  const extrudeContexts = useCallback(( nos) => {

    console.log('extrudeContexts first nos ', nos);


        // const extrudeContexts = (nos) => {
        let sourceContexts = map.current.querySourceFeatures('contexts-source');
        //remove duplicates
        sourceContexts = sourceContexts.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.properties.no === value.properties.no
          ))
        )
        //get only features we want
        let anew = sourceContexts.filter(item => nos.includes(item.properties.no));
        //order features
        function compare( a, b ) {
          if ( a.properties.elevtop > b.properties.elevtop ){
            return 1;
          }
          if ( a.properties.elevtop < b.properties.elevtop ){
            return -1;
          }
          return 0;
        }
        anew.sort( compare );
        //set customTop
        let elevHeight = 3;
        let customHeight = 2;
        let customBase = 1;
        if (nos.length>10) {
          elevHeight = 1;
          customHeight = 1;
          customBase = 0.5;
        }
        const bounds = new mapboxgl.LngLatBounds(anew[0].geometry.coordinates[0], anew[0].geometry.coordinates[1]);
        anew.forEach(e =>{
          e.properties.customTop = elevHeight;
          e.properties.customHeight = customHeight;
          e.properties.customBase = customBase;
          elevHeight = customHeight + elevHeight;
          bounds.extend(e);
        });
        //create geojson
        let geojsondata = {
          "type": "FeatureCollection",
          "features": anew
        }
        //set geojson to layer as source
        map.current.setFilter('contexts-extrude', ['>',['id'] , 0] )
        map.current.getSource("contexts-extrude").setData(geojsondata);

        if (!dontShowAgainExPop) {
          setOpenExPop(true)
        }
        if (nos.length<=3) {
          map.current.fitBounds(bounds, {
            padding: {top: 120, bottom:120, left: 300, right: 120},
            animate: true,
            linear:false,
            duration: 6000  ,
            pitch:70,
            bearing:40,
            zoom:20,
            offset:[3,3]
          });
        }
        else if (nos.length>3 && nos.length<=7) {
          map.current.fitBounds(bounds, {
            padding: {top: 120, bottom:120, left: 300, right: 120},
            animate: true,
            linear:false,
            duration: 6000  ,
            pitch:65,
            bearing:40,
            zoom:19.30,
            offset:[4,4]
          });
        }
        else if (nos.length>7 && nos.length<=12) {
          map.current.fitBounds(bounds, {
            padding: {top: 120, bottom:120, left: 300, right: 120},
            animate: true,
            linear:false,
            duration: 6000  ,
            pitch:55,
            bearing:40,
            zoom:19.80,
            offset:[5,5]
          });
        }
        else if (nos.length>12) {
          map.current.fitBounds(bounds, {
            padding: {top: 120, bottom:120, left: 300, right: 120},
            animate: true,
            linear:false,
            duration: 6000  ,
            pitch:55,
            bearing:40,
            zoom:18.70,
            offset:[6,6]
          });
        }

});//extrudeContexts   --


const zoomToMarker = (map) => {
  let l = positionMarker.marker
  map.current.flyTo({
    center: [l.lng, l.lat],
    zoom:22,
    duration:5000
  });

}

  const clickFeatureFromList = useCallback(( source, idOriginal, category, stratigraphy, stratigraphyType, stratigraphyName ) => {
    let id = Number(idOriginal)
    console.log('clickFeatureFromList id ', source, idOriginal, category, stratigraphy, stratigraphyType, stratigraphyName);

    clickFeatureFromListFitBounds = true;
    idFitBounds = id;
    sourceFitBounds = source;

    if (source !== 'source') {
      map.current.setFilter('contexts-extrude', ['==',["id"] , 0] )
      setOpenExPop(false)
      setOpenExInfo(false)
    }
    map.current.fire('closeAllPopups');

    let accordionDetailsRootGlobal = ReactDOM.createRoot(document.getElementById('featureInfoBase'));
    let stratigraphyDetailsRootGlobal = ReactDOM.createRoot(document.getElementById('stratigraphyInfoBase'));

    if (source !== 'source') {

      dispatch(fetchFeatureInfoById({id, category}))
      .then((e)=>{
        const state = store.getState()
        if (state.features.status!=='idle' && state.features.status!=='loading') {
          urlParams.delete('builing');
          urlParams.delete('part');
          urlParams.delete('context');

          let coordinates;

          console.log('pre geom ', state.features.info);

          if (JSON.parse(state.features.info.data.geom).coordinates.length>0) {
                if ( category === 'co' ) {
                  coordinates = JSON.parse(state.features.info.data.geom).coordinates[0][0]
                }
                else{
                  coordinates = JSON.parse(state.features.info.data.geom).coordinates[0][0]
                }

                // Create a 'LngLatBounds' with both corners at the first coordinate.
                const bounds = new mapboxgl.LngLatBounds(coordinates[0],coordinates[0]);

                // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
                coordinates.forEach(e =>{
                  bounds.extend(e);
                });

                map.current.fitBounds(
                  bounds,
                  {
                    padding: {top: 80, bottom:80, left: 400, right: 80},
                    animate: true,
                    linear:false,
                    duration: 6000  ,
                    pitch:0,
                    bearing:0,
                    offset:[5,5]
                  }
                );

                switch(source) {
                  case 'excavations-source':
                    map.current.setFeatureState({ sourceLayer: 'excavations', source: 'excavations-source', id },{ chosen: true });
                    break;
                  case 'excavation_parts-source':
                    map.current.setFeatureState({ sourceLayer: 'excavation_parts', source: 'excavation_parts-source', id },{ chosen: true });
                    break;
                  case 'contexts-source':
                    setTimeout(()=>{
                      let featuresCo = map.current.queryRenderedFeatures({layers:['contexts-layer']});
                      let f = featuresCo.filter((e) =>
                        e.properties.id === id
                      )
                      if (f[0]) {
                        map.current.setFeatureState({ source: 'contexts-source', id:f[0].id },{ chosen: true });
                      }
                    }, 7500);
                    break;
                  default :
                    let featuresCoD = map.current.queryRenderedFeatures({layers:['contexts-layer']});
                    let fD = featuresCoD.filter((e) =>
                      e.properties.id === id
                    )
                    if (fD[0]) {
                      map.current.setFeatureState({ source: 'contexts-source', id:fD[0].id },{ chosen: true });
                    }
                    break;
                }


                let featuresEx = map.current.queryRenderedFeatures({layers:['excavations-layer']});
                featuresEx.forEach(e => {
                  map.current.setFeatureState({ sourceLayer: 'excavations', source: 'excavations-source', id:e.id },{ chosen: false });
                });

                let featuresPa = map.current.queryRenderedFeatures({layers:['excavation_parts-layer']});
                featuresPa.forEach(e => {
                  map.current.setFeatureState({ sourceLayer: 'excavation_parts', source: 'excavation_parts-source', id:e.id },{ chosen: false });
                });

                let featuresCo = map.current.queryRenderedFeatures({layers:['contexts-layer']});
                featuresCo.forEach(e => {
                  map.current.setFeatureState({ source: 'contexts-source', id:e.id },{ chosen: false });
                });
          }//if (JSON.parse(state.features.info.data.geom).coordinates.length>0) {
          else{
            alert("No geometry found for this context.")
          }  //if else (JSON.parse(state.features.info.data.geom).coordinates.length>0) {


          if (category === 'ex') {
            setUrlParams({'building': id})
            document.title = state.features.info.data.name+' , Sikyon excavation';
            accordionDetailsRootGlobal.render(<ExcavationInfo data={state.features} />);
            setFeaturesInfoDrawer(true)
          }

          else if (category === 'pa') {            
            setUrlParams({'part': id})
            document.title = state.features.info.data.name+' , Sikyon excavation';
            accordionDetailsRootGlobal.render(<PartInfo data={state.features} setClickStateFeaturesPartInfo={setClickStateFeaturesPartInfo} unSetClickStateFeaturesPartInfo={unSetClickStateFeaturesPartInfo} clickFeatureFromListPartInfo={clickFeatureFromListPartInfo}   />);
            setFeaturesInfoDrawer(true)
          }

          else if (category === 'co') {
            setUrlParams({'context': id})
            document.title = 'Context No. '+state.features.info.data.no+' , Sikyon excavation';
            if (stratigraphy === true) {
              stratigraphyDetailsRootGlobal.render(<ContextInfo data={state.features} stratigraphy={true} stratigraphyType={stratigraphyType} stratigraphyName={stratigraphyName} prepareExtrudeContext={prepareExtrudeContext} setClickStateFeaturesPartInfo={setClickStateFeaturesPartInfo} unSetClickStateFeaturesPartInfo={unSetClickStateFeaturesPartInfo} clickFeatureFromListPartInfo={clickFeatureFromListPartInfo} />);
              setStratigraphyInfoDrawer(true)  
            }
            else{
              accordionDetailsRootGlobal.render(<ContextInfo data={state.features} stratigraphy={false} stratigraphyType={''} stratigraphyName={''} prepareExtrudeContext={prepareExtrudeContext} setClickStateFeaturesPartInfo={setClickStateFeaturesPartInfo} unSetClickStateFeaturesPartInfo={unSetClickStateFeaturesPartInfo} clickFeatureFromListPartInfo={clickFeatureFromListPartInfo} />);
              setFeaturesInfoDrawer(true)  
            }
            
          }
          

        } // if (state.features.status!=='idle' && state.features.status!=='loading') {

      })    //dispatch then

    } // if (source !== 'source') {
    else{
      dispatch(fetchFeatureInfoById({id, category}))
      .then((e)=>{
        const state = store.getState()
        if (state.features.status!=='idle' && state.features.status!=='loading') {
          urlParams.delete('builing');
          urlParams.delete('part');
          urlParams.delete('context');

          if (category === 'ex') {
            setUrlParams({'building': id})
            document.title = state.features.info.data.name+' , Sikyon excavation';
            accordionDetailsRootGlobal.render(<ExcavationInfo data={state.features} />);
            setFeaturesInfoDrawer(true)
          }
          else if (category === 'pa') {

            setUrlParams({'part': id})
            document.title = state.features.info.data.name+' , Sikyon excavation';
            accordionDetailsRootGlobal.render(<PartInfo data={state.features} setClickStateFeaturesPartInfo={setClickStateFeaturesPartInfo} unSetClickStateFeaturesPartInfo={unSetClickStateFeaturesPartInfo} clickFeatureFromListPartInfo={clickFeatureFromListPartInfo}   />);
            setFeaturesInfoDrawer(true)

          }
          else if (category === 'co') {
            setUrlParams({'context': id})
            document.title = 'Context No. '+state.features.info.data.no+' , Sikyon excavation';
            if (stratigraphy === true) {
              stratigraphyDetailsRootGlobal.render(<ContextInfo data={state.features} stratigraphy={true} stratigraphyType={stratigraphyType} stratigraphyName={stratigraphyName} prepareExtrudeContext={prepareExtrudeContext} setClickStateFeaturesPartInfo={setClickStateFeaturesPartInfo} unSetClickStateFeaturesPartInfo={unSetClickStateFeaturesPartInfo} clickFeatureFromListPartInfo={clickFeatureFromListPartInfo} />);
              setStratigraphyInfoDrawer(true)  
            }
            else{
              accordionDetailsRootGlobal.render(<ContextInfo data={state.features} stratigraphy={false} stratigraphyType={''} stratigraphyName={''} prepareExtrudeContext={prepareExtrudeContext} setClickStateFeaturesPartInfo={setClickStateFeaturesPartInfo} unSetClickStateFeaturesPartInfo={unSetClickStateFeaturesPartInfo} clickFeatureFromListPartInfo={clickFeatureFromListPartInfo} />);
              setFeaturesInfoDrawer(true)  
            } 
          } 
        }
      })
    }

    // if (source === 'source') {
    //   return
    // }



  }, [map, dispatch, setUrlParams, urlParams, extrudeContexts]);//clickFeatureFromList   --
//compiler said :  Line 559:6:   React Hook useCallback has an unnecessary dependency: 'featuresInfoDrawer'. Either exclude it or remove the dependency array


  //I could also use a useCallback here, but then excavationsList, partsList, contextsList states would also had to be in the dependenies here and in useEffect, causing calling eachother all the time
  //to avoid creating 2 states for the same thing to keep them separete, I just pass excavationsList, partsList, contextsList as arguments, to have the state updated with no depenedncies here


  function compare( a, b ) {
    console.log('compare called');
    if ( a.properties.elevtop > b.properties.elevtop ){
      return -1;
    }
    if ( a.properties.elevtop < b.properties.elevtop ){
      return 1;
    }
    return 0;
  }


  const showDrawerList=(category, id, setPartsList, setContextsList) => {

    setExtrudeAllClicked(false)

    if (category === 'ex') {
      setHighlightExArrowId(id)
      let parts = [];
      let contexts = [];
      let excavation = clickedFeatures.excavations.filter((ex) =>
        ex.properties.id === id
      )
      if (excavation.length>0) {
        excavation = excavation[0]
        parts = clickedFeatures.parts.filter((pa) =>
          turf.booleanIntersects(excavation,pa) || turf.booleanContains(excavation,pa)
        )
        if (parts.length>0) {
          setPartsList(parts)
          setContextsList([])
        }
        else{
          setPartsList([{id:-1, properties:{name:'No Parts Found', id:-1}, name:'No Parts Found'}])
          contexts = clickedFeatures.contexts.filter((co) =>
            turf.booleanIntersects(excavation,co) || turf.booleanContains(excavation,co)
          )
          if (contexts.length>0) {
            contexts.sort( compare );
            setContextsList(contexts)
          }
          else{
            setContextsList([{id:-1, properties:{no:'No Contexts Found', id:-1}, no:'No Contexts Found'}])
          }
        }
      }
    }

    if (category === 'pa') {
      setHighlightPaArrowId(id)
      let contexts = []  ;
      let part = clickedFeatures.parts.filter((pa) =>
        pa.properties.id === id
      )
      if (part.length>0) {
        part = part[0]
        contexts = clickedFeatures.contexts.filter((co) =>
          turf.booleanIntersects(part,co) || turf.booleanContains(part,co)
        )
        if (contexts.length>0) {
          contexts.sort( compare );
          setContextsList(contexts)
        }
        else{
          setContextsList([{id:-1, properties:{no:'No Contexts Found', id:-1}, no:'No Contexts Found'}])
        }
      }
    }

  };//showDrawerList
 

  const showDrawerResultList=(category, id, setPartsResultsList, setContextsResultsList) => {

    setExtrudeAllClickedSearch(false) 

    if (category === 'ex') {
      setHighlightExSearchArrowId(id)
      let parts = [];
      let contexts = [];
      let excavation = resultFeatures.excavations.filter((ex) =>
        ex.properties.id === id
      )
      if (excavation.length>0) {
        excavation = excavation[0]
        parts = resultFeatures.parts.filter((pa) =>
          turf.booleanIntersects(excavation,pa) || turf.booleanContains(excavation,pa)
        )
        if (parts.length>0) {
          setPartsResultsList(parts)
          setContextsResultsList([])
        }
        else{
          setPartsResultsList([{id:-1, properties:{name:'No Parts Found', id:-1}, name:'No Parts Found'}])
          contexts = resultFeatures.contexts.filter((co) =>
            turf.booleanIntersects(excavation,co) || turf.booleanContains(excavation,co)
          )
          if (contexts.length > 0) {
            contexts.sort( compare );
            setContextsResultsList(contexts)
          }
          else{
            setContextsResultsList([{id:-1, properties:{no:'No Contexts Found', id:-1}, no:'No Contexts Found'}])
          }
        }
      }
    }

    if (category === 'pa') {
      setHighlightPaSearchArrowId(id)
      let contexts = []  ;
      let part = resultFeatures.parts.filter((pa) =>
        pa.properties.id === id
      )
      if (part.length>0) {
        part = part[0]
        contexts = resultFeatures.contexts.filter((co) =>
          turf.booleanIntersects(part,co) || turf.booleanContains(part,co)
        )
        if (contexts.length > 0) {
          contexts.sort( compare );
          setContextsResultsList(contexts)
        }
        else{
          setContextsResultsList([{id:-1, properties:{no:'No Contexts Found', id:-1}, no:'No Contexts Found'}])
        }
      }
    }

  };//showDrawerResultList


  // const handleContextsDropDownChange = (e, v, setSearchParams) => {
  //   setSearchParams(prevState => ({
  //     ...prevState,
  //     contexts: v
  //   }))
  // }

  const handlePeriodsDropDownChange = (e, v, setSearchParams) => {
    setSearchParams(prevState => ({
      ...prevState,
      periods: v
    }))
  }




  const searchByCategories = useCallback((searchParams) => {
    if (searchParams.contexts === false && searchParams.buildings === false && searchParams.periods === false) {
      return
    }
    if (searchParams.contexts === null && searchParams.buildings === null && searchParams.periods === null) {
      return
    }
    setLoadingVisible(true)
    setResultsMessageVisible(false);
    setResultsMessage('')
    dispatch(fetchSearchResults(searchParams))
    .then((e)=>{
      const state = store.getState()
      setLoadingVisible(false)
      if (state.search.results.status!=='idle' && state.search.results.status!=='loading') {
        setExcavationsResultsList([])
        setPartsResultsList([])
        setContextsResultsList([])
        if (state.search.results.error!=='null' || !state.search.results.info.success) {
          setResultsMessageVisible(true);
          setResultsMessage('An error occured. Please try again')
          setLayerDrawer(false)
          // setResultsDrawer(true)
          //did include setFeaturesInfoDrawer in depenedncies, for the sake of simplecity, hoping it will not cause any error in the future
          setFeaturesInfoDrawer(false);
          setStratigraphyInfoDrawer(false);
          return
        }
        if (state.search.results.info.data.empty) {
          setResultsMessageVisible(true);
          setResultsMessage('No results')
          setLayerDrawer(false)
          // setResultsDrawer(true)
          //did include setFeaturesInfoDrawer in depenedncies, for the sake of simplecity, hoping it will not cause any error in the future
          setFeaturesInfoDrawer(false);
          setStratigraphyInfoDrawer(false);
          return
        }


          let excavations = state.search.results.info.data.excavations;
          let parts = state.search.results.info.data.parts;
          let contexts = state.search.results.info.data.contexts;

          setResultFeatures(prevState => ({
            ...prevState,
            parts
          }))
          setResultFeatures(prevState => ({
            ...prevState,
            excavations
          }))
          setResultFeatures(prevState => ({
            ...prevState,
            contexts
          }))

          if (excavations.length>0) {
            setExcavationsResultsList(excavations)
          }
          else{
            if (parts.length>0) {
              setPartsResultsList(parts)
            }
            else{
              setContextsResultsList(contexts)
            }
          }
          setLayerDrawer(false)
          // setResultsDrawer(true)
          setFeaturesInfoDrawer(false);
          setStratigraphyInfoDrawer(false);
          map.current.setFilter('contexts-extrude', ['==',["id"] , 0] )
          setOpenExInfo(false)
          setOpenExPop(false)
      }
    })

  }, [dispatch]);//searchByCategories
  //compliler said : Line 759:6:   React Hook useCallback has unnecessary dependencies: 'contextsResultsList', 'excavationsResultsList', 'layerDrawer', 'partsResultsList', 'resultFeatures', and 'resultsDrawer'. Either exclude them or remove the dependency array

  useEffect(() => {
    document.title = 'Map , Sikyon excavation';
    const acceptCookies = localStorage.getItem("acceptCookies");
    setAcceptCookiesValue(acceptCookies)
    if (acceptCookies === 'r') {
      return
    }
    const storedValue = localStorage.getItem("dontShowAgainExPop");
    if (storedValue) {
      setDontShowAgainExPop(JSON.parse(storedValue));
    }
  }, []);

  useEffect(() => {
    const acceptCookies = localStorage.getItem("acceptCookies");
    if (acceptCookies === 'r') {
      return
    }
    localStorage.setItem("dontShowAgainExPop", JSON.stringify(dontShowAgainExPop));
  }, [dontShowAgainExPop]);

  useEffect(() => {

    //[1]
    if (map.current) return; // initialize map only once
    mapboxgl.accessToken = 'pk.eyJ1IjoiZGlweWxvbiIsImEiOiJjbDk2dWE1c24wOW4zM3JsbWxrMXdxNTg3In0.6cJgXvlJtS1QFXkRoplc9g';

    let hoveredStateId = null;
    let hoveredStateIdExcavations = null;
    let hoveredStateIdExcavationParts = null;
    let hoveredStateIdContextExtrude = null;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style:'mapbox://styles/mapbox/satellite-streets-v11',
      center: [22.7127, 37.9838],
      zoom: 16.40,
      attributionControl: false
    });

    mapDraw.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        point: false,
        line_string: false,
        polygon: false,
        trash: false
      }
    });

    map.current.on('load', () => {
        let sourceURL = 'https://sikyonexcavation.gr'
        if (process.env.REACT_APP_ENV === 'dev') {
          sourceURL = 'http://localhost:8585'
        }

        map.current.addSource('contexts-source', {
          type: 'geojson',
          data: sourceURL+'/geoserver/sikyona/ows?service=WFS&version=1.0.0&TILED=true&WIDTH=256&HEIGHT=256&request=GetFeature&typeName=sikyona%3Acontexts&outputFormat=application%2Fjson' ,
          generateId: true
        });

        map.current.addSource('excavations-source', {
          type: 'vector',
          tiles: [
            sourceURL+'/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=sikyona:excavations&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}'
          ],
          'minZoom': 0,
          'maxZoom': 14,
        });

        map.current.addSource('excavation_parts-source', {
          type: 'vector',
          tiles: [
            sourceURL+'/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=sikyona:excavation_parts&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}'
          ],
          'minZoom': 0,
          'maxZoom': 14,
        });

        map.current.addSource('contexts-extrude', {
          type: 'geojson',
          data: {
            'type': 'FeatureCollection',
            'features': []
          }
        });


        map.current.addLayer({
          'id': 'apollo',
          'type': 'raster',
          'source': {
            'type': 'raster',
            'tiles': [
              sourceURL+'/geoserver/sikyona/wms?service=WMS&version=1.1.0&request=GetMap&layers=sikyona%3Aapollo&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG%3A900913&format=image/png&transparent=true'
            ],
            'tileSize':256,
          },
          'minzoom': 16.5
        });

        map.current.addLayer({
          'id': 'bouleutirion',
          'type': 'raster',
          'source': {
            'type': 'raster',
            'tiles': [
              sourceURL+'/geoserver/sikyona/wms?service=WMS&version=1.1.0&request=GetMap&layers=sikyona%3Abouleutirion&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG%3A900913&format=image/png&transparent=true'
            ],
            'tileSize':256,
          },
          'minzoom': 16.5
        });

        map.current.addLayer({
          'id': 'complex',
          'type': 'raster',
          'source': {
            'type': 'raster',
            'tiles': [
              sourceURL+'/geoserver/sikyona/wms?service=WMS&version=1.1.0&request=GetMap&layers=sikyona%3Acomplex&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG%3A900913&format=image/png&transparent=true'
            ],
            'tileSize':256,
          },
          'minzoom': 16.5
        });

        map.current.addLayer({
          'id': 'gymnasion',
          'type': 'raster',
          'source': {
            'type': 'raster',
            'tiles': [
              sourceURL+'/geoserver/sikyona/wms?service=WMS&version=1.1.0&request=GetMap&layers=sikyona%3Agymnasio&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG%3A900913&format=image/png&transparent=true'
            ],
            'tileSize':256,
          },
          'minzoom': 16.5
        });

        map.current.addLayer({
          'id': 'naos',
          'type': 'raster',
          'source': {
            'type': 'raster',
            'tiles': [
              sourceURL+'/geoserver/sikyona/wms?service=WMS&version=1.1.0&request=GetMap&layers=sikyona%3Anaos&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG%3A900913&format=image/png&transparent=true'
            ],
            'tileSize':256,
          },
          'minzoom': 16.5
        });

        map.current.addLayer({
          'id': 'south_stoa',
          'type': 'raster',
          'source': {
            'type': 'raster',
            'tiles': [
              sourceURL+'/geoserver/sikyona/wms?service=WMS&version=1.1.0&request=GetMap&layers=sikyona%3Asouth_stoa&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG%3A900913&format=image/png&transparent=true'
            ],
            'tileSize':256,
          },
          'minzoom': 16.5
        });

        map.current.addLayer({
          'id': 'theatro',
          'type': 'raster',
          'source': {
            'type': 'raster',
            'tiles': [
              sourceURL+'/geoserver/sikyona/wms?service=WMS&version=1.1.0&request=GetMap&layers=sikyona%3Atheatro&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG%3A900913&format=image/png&transparent=true'
            ],
            'tileSize':256,
          },
          'minzoom': 16.5
        });

        map.current.addLayer({
          'id': 'trench',
          'type': 'raster',
          'source': {
            'type': 'raster',
            'tiles': [
              sourceURL+'/geoserver/sikyona/wms?service=WMS&version=1.1.0&request=GetMap&layers=sikyona%3Atrench&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG%3A900913&format=image/png&transparent=true'
            ],
            'tileSize':256,
          },
          'minzoom': 16.5
        });

        map.current.addLayer({
          'id': 'workshops',
          'type': 'raster',
          'source': {
            'type': 'raster',
            'tiles': [
              sourceURL+'/geoserver/sikyona/wms?service=WMS&version=1.1.0&request=GetMap&layers=sikyona%3Aworkshops&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG%3A900913&format=image/png&transparent=true'
            ],
            'tileSize':256,
          },
          'minzoom': 16.5
        });

        map.current.addLayer({'id': 'contexts-layer',
          'type': 'fill',
          'source': 'contexts-source',
          'paint': {
            'fill-color': 'rgb(0, 0, 255)',
            'fill-opacity':[
              'case',
              ['boolean', ['feature-state', 'hover'], false],0.4,
              ['boolean', ['feature-state', 'click'], false],0.6,
              ['boolean', ['feature-state', 'chosen'], false],0.9,
              0.3
            ]
          }
        });

        map.current.addLayer({'id': 'contexts-labels',
          'type': 'symbol',
          'source': 'contexts-source',
          'layout': {
            'text-field': ['get', 'no'],
            'symbol-placement': 'point',
            'text-offset':[0,-0.6],
            'text-size': 12,
            'visibility': 'visible'
          },
          'paint':{
            'text-color':[
              'case',
              ['boolean', ['feature-state', 'click'], false],'white',
              ['boolean', ['feature-state', 'hover'], false],'white',
              ['boolean', ['feature-state', 'chosen'], false],'yellow',
              'rgb(70, 70, 70)'
            ]
          }
        });

        map.current.addLayer({'id': 'contexts-line',
          'type': 'line',
          'source': 'contexts-source',
          'paint': {
            'line-color':[
              'case',
              ['boolean', ['feature-state', 'click'], false],'rgb(255, 255, 255)',
              ['boolean', ['feature-state', 'hover'], false],'rgb(255, 255, 255)',
              ['boolean', ['feature-state', 'chosen'], false],'yellow',
              'rgb(110, 110, 246)'
            ],
            'line-width':[
              'case',
              ['boolean', ['feature-state', 'click'], false],2,
              ['boolean', ['feature-state', 'hover'], false],2,
              ['boolean', ['feature-state', 'chosen'], false],5,
              1
            ]
          }
        });

        map.current.addLayer({'id': 'contexts-extrude',
        'type': 'fill-extrusion',
        'source': 'contexts-extrude',
        'paint': {
          'fill-extrusion-color':[
            'case',
            ['boolean', ['feature-state', 'hover'], false],'rgb(253, 255, 0)',
            ['boolean', ['feature-state', 'click'], false],'rgb(253, 255, 0)',
            'rgb(253, 255, 72)'
          ] ,
          'fill-extrusion-opacity':0.6,
          'fill-extrusion-height': ['+',['to-number', ['get', 'customTop']] , ['to-number', ['get', 'customHeight']]],
          'fill-extrusion-base':  ['+',['to-number', ['get', 'customTop']], ['to-number', ['get', 'customBase']]]
        }
        });

        map.current.addLayer({'id': 'excavation_parts-layer',
          'type': 'fill',
          'source': 'excavation_parts-source',
          'source-layer': 'excavation_parts',
          'layout': {
            'visibility': 'visible'
          },
          'paint': {
            'fill-color': 'green',
            'fill-opacity':[
              'case',
              ['boolean', ['feature-state', 'hover'], false],0.4,
              ['boolean', ['feature-state', 'click'], false],0.4,
              ['boolean', ['feature-state', 'chosen'], false],0.6,
              0.3
            ]
          }
        });

        //'text-offset':[0,-0.6],
        // 'text-ignore-placement':true,
        // 'text-allow-overlap':true,
        map.current.addLayer({'id': 'excavation_parts-labels',
          'type': 'symbol',
          'source': 'excavation_parts-source',
          'source-layer': 'excavation_parts',
          'layout': {
            'text-field': ['get', 'name'],
            'symbol-placement': 'point',
            'text-offset':[0,-0.6],
            'text-size': 12,
            'visibility': 'visible'
          },
          'paint':{
            'text-color':[
              'case',
              ['boolean', ['feature-state', 'click'], false],'white',
              ['boolean', ['feature-state', 'hover'], false],'white',
              ['boolean', ['feature-state', 'chosen'], false],'yellow',
              'black'
            ]
          }
        });

        map.current.addLayer({'id': 'excavation_parts-line',
          'type': 'line',
          'source': 'excavation_parts-source',
          'source-layer': 'excavation_parts',
          'paint': {
            'line-color':[
              'case',
              ['boolean', ['feature-state', 'click'], false],'rgb(255, 255, 255)',
              ['boolean', ['feature-state', 'hover'], false],'rgb(255, 255, 255)',
              ['boolean', ['feature-state', 'chosen'], false],'yellow',
              'rgb(0, 255, 0)'
            ],
            'line-width':[
              'case',
              ['boolean', ['feature-state', 'click'], false],2,
              ['boolean', ['feature-state', 'hover'], false],2,
              ['boolean', ['feature-state', 'chosen'], false],3,
              1
            ]
          }
        });

        map.current.addLayer({'id': 'excavations-layer',
          'type': 'fill',
          'source': 'excavations-source',
          'source-layer': 'excavations',
          'paint': {
            'fill-color': [
              'interpolate',
              ['linear'],
              ['get', 'level'],
              1,'rgb(255,0,0)',
              2,'rgb(194,0,0)',
              3,'rgb(150,0,0)'
            ],
            'fill-opacity':[
              'case',
              ['boolean', ['feature-state', 'hover'], false],0.4,
              ['boolean', ['feature-state', 'click'], false],0.4,
              ['boolean', ['feature-state', 'chosen'], false],0.6,
              0.3
            ]
          }
        });

        map.current.addLayer({'id': 'excavations-labels',
          'type': 'symbol',
          'source': 'excavations-source',
          'source-layer': 'excavations',
          'layout': {
            'text-field': ['get', 'name'],
            'symbol-placement': 'point',
            'text-offset':[0,-0.6],
            'text-size': 14,
            'visibility': 'visible'
          },
          'paint':{
            'text-color':[
              'case',
              ['boolean', ['feature-state', 'click'], false],'white',
              ['boolean', ['feature-state', 'hover'], false],'white',
              ['boolean', ['feature-state', 'chosen'], false],'yellow',
              'black'
            ]
          }
        });

        map.current.addLayer({'id': 'excavations-line',
          'type': 'line',
          'source': 'excavations-source',
          'source-layer': 'excavations',
          'paint': {
            'line-color':[
              'case',
              ['boolean', ['feature-state', 'click'], false],'rgb(255, 255, 255)',
              ['boolean', ['feature-state', 'hover'], false],'rgb(255, 255, 255)',
              ['boolean', ['feature-state', 'chosen'], false],'yellow',
              'rgb(255, 120, 120)'
            ] ,
            "line-dasharray": [
              "match", ["get", "level"],
              2, ["literal", [4, 2]],
              3, ["literal", [1, 6]],
              ["literal", [1, 0]]
            ],
            'line-width':[
              'case',
              ['boolean', ['feature-state', 'click'], false],2,
              ['boolean', ['feature-state', 'hover'], false],2,
              ['boolean', ['feature-state', 'chosen'], false],3,
              1
            ]
          }
        });

        // map.current.addSource('workshop-source', {
        //   type: 'raster',
        //   tiles: [
        //     'http://localhost:8080/geoserver/gwc/demo/sikyona:ortho_ortho_part_1_1?gridSet=EPSG:4326&format=image/png'
        //   ],
        //   maxzoom: 22,
        //   minzoom: 0,
        // });

        // map.current.addLayer({'id': 'workshop-layer',
        //   'type': 'raster',
        //   'source': 'workshop-source'   ,
        //   'rasterOpacity': 0.5,
        //   'attribution':'sm sm sm sm sm sm sm sm sm sm '
        // });




        if (urlParams.get('building')) {
          clickFeatureFromList('excavations-source', urlParams.get('building'), 'ex', false, '', '')
        }
        else if(urlParams.get('part')){
          clickFeatureFromList('excavation_parts-source', urlParams.get('part'), 'pa', false,'','')
        }
        else if (urlParams.get('context')){
          globalCU = urlParams.get('context')
          clickFeatureFromList('contexts-source', urlParams.get('context'), 'co', false,'','')
        }

    });//map load

    map.current.addControl(mapDraw.current);
    map.current.on('draw.create', updateArea);
    map.current.on('draw.delete', updateArea);
    map.current.on('draw.update', updateArea);


    map.current.on('idle',(e)=>{
      setOpenBackDrop(false)
      if (!globalCU) {
        return
      }
      // let featuresCoSearch = map.current.queryRenderedFeatures({layers:['contexts-layer']});
      // featuresCoSearch.forEach(e => {
      //   globalCU = Number(globalCU)
      //   if (e.properties.id === globalCU) {
      //     map.current.setFeatureState({ source: 'contexts-source', id: e.id },{ chosen: true });
      //   }
      // });
      // globalCU = false

      // if (clickFeatureFromListFitBounds === true) {

      //     let id = idFitBounds;
      //     let source = sourceFitBounds;
      //     switch(source) {
      //       case 'excavations-source':
      //         map.current.setFeatureState({ sourceLayer: 'excavations', source: 'excavations-source', id },{ chosen: true });
      //         break;
      //       case 'excavation_parts-source':
      //         map.current.setFeatureState({ sourceLayer: 'excavation_parts', source: 'excavation_parts-source', id },{ chosen: true });
      //         break;
      //       case 'contexts-source':
      //         // setTimeout(()=>{
      //           let featuresCo = map.current.queryRenderedFeatures({layers:['contexts-layer']});
      //           let f = featuresCo.filter((e) =>
      //             e.properties.id === id
      //           )
      //           if (f[0]) {
      //             map.current.setFeatureState({ source: 'contexts-source', id:f[0].id },{ chosen: true });
      //           }
      //         // }, 2500);
      //         break;
      //       default :
      //         let featuresCoD = map.current.queryRenderedFeatures({layers:['contexts-layer']});
      //         let fD = featuresCoD.filter((e) =>
      //           e.properties.id === id
      //         )
      //         if (fD[0]) {
      //           map.current.setFeatureState({ source: 'contexts-source', id:fD[0].id },{ chosen: true });
      //         }
      //         break;
      //     }

      //     clickFeatureFromListFitBounds = false;

      // }

   })

    map.current.on('mousemove', 'contexts-layer', (e) => {
      map.current.getCanvas().style.cursor = 'pointer'
      if (e.features.length > 0) {
        if (hoveredStateId !== null) {
          map.current.setFeatureState(
            { source: 'contexts-source', id: hoveredStateId },
            { hover: false }
          );
        }

        hoveredStateId=e.features[0].id;
        map.current.setFeatureState(
            { source: 'contexts-source', id: hoveredStateId },
            { hover: true }
        );
      }
    });

    map.current.on('mouseleave', 'contexts-layer', () => {
      map.current.getCanvas().style.cursor = ''
      if (hoveredStateId !== null) {
        map.current.setFeatureState(
            { source: 'contexts-source', id: hoveredStateId },
            { hover: false }
        );
      }
      hoveredStateId=null;
    });

    map.current.on('mousemove', 'excavations-layer', (e) => {
      map.current.getCanvas().style.cursor = 'pointer'
      if (e.features.length > 0) {
        if (hoveredStateIdExcavations !== null) {
          map.current.setFeatureState(
            { sourceLayer: 'excavations', source: 'excavations-source', id: hoveredStateIdExcavations },
            { hover: false }
          );
        }

        hoveredStateIdExcavations=e.features[0].id;
        map.current.setFeatureState(
            { sourceLayer: 'excavations', source: 'excavations-source', id: hoveredStateIdExcavations },
            { hover: true }
        );
      }
    });

    map.current.on('mouseleave', 'excavations-layer', () => {
      map.current.getCanvas().style.cursor = ''
      if (hoveredStateIdExcavations !== null) {
        map.current.setFeatureState(
            { sourceLayer: 'excavations', source: 'excavations-source', id: hoveredStateIdExcavations },
            { hover: false }
        );
      }
      hoveredStateIdExcavations=null;
    });

    map.current.on('mousemove', 'excavation_parts-layer', (e) => {
      map.current.getCanvas().style.cursor = 'pointer'
      if (e.features.length > 0) {
        if (hoveredStateIdExcavationParts !== null) {
          map.current.setFeatureState(
            { sourceLayer: 'excavation_parts', source: 'excavation_parts-source', id: hoveredStateIdExcavationParts },
            { hover: false }
          );
        }

        hoveredStateIdExcavationParts=e.features[0].id;
        map.current.setFeatureState(
            { sourceLayer: 'excavation_parts', source: 'excavation_parts-source', id: hoveredStateIdExcavationParts },
            { hover: true }
        );
      }
    });

    map.current.on('mouseleave', 'excavation_parts-layer', () => {
      map.current.getCanvas().style.cursor = ''
      if (hoveredStateIdExcavationParts !== null) {
        map.current.setFeatureState(
            { sourceLayer: 'excavation_parts', source: 'excavation_parts-source', id: hoveredStateIdExcavationParts },
            { hover: false }
        );
      }
      hoveredStateIdExcavationParts=null;
    });

    map.current.on('mousemove', 'contexts-extrude', (e) => {
      map.current.getCanvas().style.cursor = 'pointer'
      if (e.features.length > 0) {
        let feature = e.features[0]
        setExInfoText('Context No. '+feature.properties.no+' , '+feature.properties.type)
        setOpenExInfo(true)
        if (hoveredStateIdContextExtrude !== null) {
          map.current.setFeatureState(
            { source: 'contexts-extrude', id: hoveredStateIdContextExtrude },
            { hover: false }
          );
        }

        hoveredStateIdContextExtrude=e.features[0].id;
        map.current.setFeatureState(
            { source: 'contexts-extrude', id: hoveredStateIdContextExtrude },
            { hover: true }
        );
      }
    });

    map.current.on('mouseleave', 'contexts-extrude', () => {
      map.current.getCanvas().style.cursor = ''
      if (hoveredStateIdContextExtrude !== null) {
        setExInfoText('')
        setOpenExInfo(false)
        map.current.setFeatureState(
            { source: 'contexts-extrude', id: hoveredStateIdContextExtrude },
            { hover: false }
        );
      }
      hoveredStateIdContextExtrude = null;
    });

    map.current.on('click', (e) => {

      if (mapDraw.current.getMode() === 'draw_polygon' || mapDraw.current.getMode() === 'draw_line_string') {
        return
      }

      if (mapDraw.current.getMode() === 'direct_select' && mapDraw.current.getAll().features.length > 0) {
        return
      }

      if (dbClick === true) {
        dbClick = false
        return;
      }

      dbClick = false

      // setLayerDrawer(false)
      ////did include setFeaturesInfoDrawer in depenedncies, for the sake of simplecity, hoping it will not cause any error in the future
      setFeaturesInfoDrawer(false)
      setStratigraphyInfoDrawer(false);

      const point = e.point;
      let features = map.current.queryRenderedFeatures(point);

      if (globalMarker !== 'empty') {
        globalMarker.remove();
      }

      if (features.length === 0) {
        return
      }

      let markerPoint = e.lngLat.wrap()
      globalMarker = new mapboxgl.Marker().setLngLat([markerPoint.lng, markerPoint.lat]).addTo(map.current);
      let lnglat = globalMarker.getLngLat()
      setPositionMarker(prevState => ({
        ...prevState,
        marker:lnglat
      }))

      let extrudedCheck = features.filter(f => f.layer.id === 'contexts-extrude');

      if (extrudedCheck.length>0) {
        clickFeatureFromList( 'source', extrudedCheck[0].properties.id,'co', false,'','')
        return;
      }

      let excavations = features.filter(f => f.layer.id === 'excavations-layer');
      let parts = features.filter(f => f.layer.id === 'excavation_parts-layer');
      let contexts = features.filter(f => f.layer.id === 'contexts-layer');

      drawerListFeeder(excavations, parts, contexts)
    })   //map on click

    const drawerListFeeder = (excavations, parts, contexts) => {

      setExcavationsList([])
      setPartsList([])
      setContextsList([])

      map.current.setFilter('contexts-extrude', ['==',["id"] , 0] )
      setOpenExPop(false)
      setOpenExInfo(false)
      map.current.fire('closeAllPopups');
      //removing duplicates
      excavations = excavations.filter((value, index, self) =>
        index === self.findIndex((et) => (
          et.id === value.id
        ))
      )
      parts = parts.filter((value, index, self) =>
        index === self.findIndex((pt) => (
          pt.id === value.id
        ))
      )
      contexts = contexts.filter((value, index, self) =>
        index === self.findIndex((ct) => (
          ct.id === value.id
        ))
      )

      setClickedFeatures(prevState => ({
        ...prevState,
        excavations: excavations
      }))

      setClickedFeatures(prevState => ({
        ...prevState,
        parts: parts
      }))

      setClickedFeatures(prevState => ({
        ...prevState,
        contexts: contexts
      }))

      if (excavations.length>0) {
        setExcavationsList(excavations)
      }
      else{
        if (parts.length>0) {
          setPartsList(parts)
        }
        else{
          setContextsList(contexts)
        }
      }
      //did not put any of those on useEffect depenedncies, for the sake of simplecity, hoping it will not cause any error in the future
      setLayerDrawer(true)
      // setResultsDrawer(false)
    }//drawerListFeeder

    dispatch(fetchSearchCategories())
    // return () => map.current.remove(); // clean up on unmount

}, [excavationsList, partsList, contextsList, layerDrawer, clickedFeatures, clickFeatureFromList, dispatch, urlParams]); //useEffect
  //featuresInfoDrawer is missing form the dependecies array of useEffect because having it both here and useCallback will cause useEffect to re-run everytime useCallback is clicked
  //featuresInfoDrawer doesnt have any side-effect, so its safe to remove from useEffect

  const handleDrawLine = () => {
    dbClick = false
    setMeasureLine('')
    setMeasurePolygon('')
    mapDraw.current.deleteAll();
    mapDraw.current.changeMode('draw_line_string');
  }

  const handleDrawPolygon = () => {
    dbClick = false
    setMeasureLine('')
    setMeasurePolygon('')
    mapDraw.current.deleteAll();
    mapDraw.current.changeMode('draw_polygon');
  }

  const handleDrawDelete = () => {
    dbClick = false
    setMeasureLine('')
    setMeasurePolygon('')
    mapDraw.current.deleteAll();
  }

  const updateArea = (e) => {

    dbClick = true

    if (mapDraw.current.getMode() === 'draw_polygon') {
      const data = mapDraw.current.getAll();
      if (data.features.length > 0) {
        const area = turf.area(data);
        const rounded_area = Math.round(area * 100) / 100;
        let finalArea = rounded_area + ' square meters'
        setMeasurePolygon(finalArea)
      }
    }

    if (mapDraw.current.getMode() === 'draw_line_string') {
      const data = mapDraw.current.getAll();
      if (data.features.length > 0) {
        // console.log('LINE ', data.features);
        if (data.features[0].geometry.type === 'LineString') {
          // const coords = data.features[0].geometry.coordinates;
          // const startPoint = turf.point(coords[0]);
          // const endPoint = turf.point(coords[coords.length - 1]);
          // const distance = turf.distance(startPoint, endPoint);
          // const rounded_distance = Math.round(distance * 1000) / 1000;
          // let finalDistance = rounded_distance + ' m';
          // setMeasureLine(finalDistance);
          const coordinates = data.features[0].geometry.coordinates;
          const lineString = turf.lineString(coordinates);
          const lengthInKilometers = turf.length(lineString);
          const lengthInMeters = Math.round(lengthInKilometers * 1000 * 100) / 100;
          let finalDistance = lengthInMeters + ' m';
          setMeasureLine(finalDistance);
        }
      }
    }

    if (mapDraw.current.getMode() === 'direct_select') {
      dbClick = true
      const data = mapDraw.current.getAll();
      if (data.features.length > 0) {
        if (data.features[0].geometry.type === 'LineString') {
          // const coords = data.features[0].geometry.coordinates;
          // const startPoint = turf.point(coords[0]);
          // const endPoint = turf.point(coords[coords.length - 1]);
          // const distance = turf.distance(startPoint, endPoint, {units:'meters'});
          // const rounded_distance = Math.round(distance * 100) / 100;
          // let finalDistance = rounded_distance + ' m';
          // setMeasureLine(finalDistance);
          const coordinates = data.features[0].geometry.coordinates;
          const lineString = turf.lineString(coordinates);
          const lengthInKilometers = turf.length(lineString);
          const lengthInMeters = Math.round(lengthInKilometers * 1000 * 100) / 100;
          let finalDistance = lengthInMeters + ' m';
          setMeasureLine(finalDistance);
        }

        if (data.features[0].geometry.type === 'Polygon') {
          // console.log('polygon ');
          const area = turf.area(data);
          const rounded_area = Math.round(area * 100) / 100;
          let finalArea = rounded_area + ' square meters'
          setMeasurePolygon(finalArea)
        }

      }

      e.stopPropagation();

    }
  }

  const handleBuildingsDropDownChange = (e, v, setSearchParams) => {
    console.log(' H E L L O  e ' ,  e);
    console.log(' H E L L O  v ' ,  v);
    console.log(' H E L L O  search params ' ,  searchParams);
    setSearchParams(prevState => ({
      ...prevState,
      buildings: v
    }))
    setBuildingsAutocompleteVal(v)
  }

  const handleContextsDropDownChange = (e, v, setSearchParams) => {
    setSearchParams(prevState => ({
      ...prevState,
      contexts: v
    }))
    setContextsAutocompleteVal(v)
  }

  const gotoBuilding = (searchParams) => {
    console.log('gotoBuilding  ', searchParams.buildings.id);
    clickFeatureFromList('excavations-source', searchParams.buildings.id, 'ex', false,'','')
    toggleGotoDrawer(setGotoDrawer)
  }

  const gotoContext = (searchParams) => {
    console.log('gotoContext  ', searchParams.contexts.id);
    clickFeatureFromList('contexts-source', searchParams.contexts.id, 'co', false,'','')
    toggleGotoDrawer(setGotoDrawer)
  }

  return (
    <div className='general-container'  >

        {/* search bar */}

        {/* {
        searchCategoriesInfoSelector.success!==true?
        <Box sx={{display:'inline-block', textAlign:'center', margin:'0 auto', width:'100%', padding:'5px'}}>Loading search bar</Box>
        :
        searchCategoriesInfoSelector.data.periods && searchCategoriesInfoSelector.data.periods.length>0 &&
        searchCategoriesInfoSelector.data.buildings && searchCategoriesInfoSelector.data.buildings.length>0 &&
        searchCategoriesInfoSelector.data.contexts && searchCategoriesInfoSelector.data.contexts.length>0?

        <Box
          sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 1,
              m: 1,
              bgcolor: '#f3ebeb',
              margin:'4px 0',
              padding:'4px 0'
            }}
        >

          <Autocomplete
            disablePortal
            id="combo-box-buildings"
            options={searchCategoriesInfoSelector.data.buildings}
            sx={{ width: 300 , margin:'0 10px'}}
            size='small'
            onChange={(event, value)=>handleBuildingsDropDownChange(event, value, setSearchParams)}
            renderInput={(params) => <TextField {...params} label="Select a Building" />}
          />

          <Autocomplete
            disablePortal
            id="combo-box-contexts"
            options={searchCategoriesInfoSelector.data.contexts}
            getOptionLabel={(option) => option.label.toString()}
            sx={{ width: 300 , margin:'0 10px' }}
            size='small'
            onChange={(event, value)=>handleContextsDropDownChange(event, value, setSearchParams)}
            renderInput={(params) => <TextField {...params} label="Select a Context" />}
          />

          <Autocomplete
            disablePortal
            id="combo-box-periods"
            options={searchCategoriesInfoSelector.data.periods}
            sx={{ width: 300 , margin:'0 10px' }}
            size='small'
            onChange={(event, value)=>handlePeriodsDropDownChange(event, value, setSearchParams)}
            renderInput={(params) => <TextField {...params} label="Select a Period" />}
          />

          <Button
            size='small'
            variant="outlined"
            sx={{ margin:'0 10px' }}
            style={{textTransform: 'none'}}
            loading={loadingVisible}
            onClick={()=>searchByCategories(searchParams)}
          >
            Search
            <CircularProgress sx={{visibility:'hidden', position:'fixed', color:'lightgrey'}} className={loadingVisible ? 'visibleSearchLoading' :''} />
          </Button >

        </Box>
        :
        'loading search bar'
      } */}

      {/* legend */}
      {/* <Button
        ref={anchorLegend}
        onClick={toggleLegend}
        sx={{
          position:'absolute',
          top:'555px',
          right:'0px',
          backgroundColor:'white',
          zIndex:'2',
          minWidth:'24px!important',
          '&:hover': { backgroundColor: 'white'}
      }} >
        <LegendToggleIcon/>
      </Button>

      <div id='legendHook'
        ref={anchorLegend}
      >
      </div>

      <Popper
        open={openLegend}
        anchorEl={anchorLegend.current}
        anchorPosition={{ top: '100px', left: '1000px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box  sx={{  bgcolor: 'background.paper' }}>
          <div id='legendBox'>
          <Paper elevation={2}>
            <Box padding={2}>
              <Typography  sx={{textAlign:'center'}} gutterBottom>
                Map Legend
              </Typography>
              <div className='legendRow'>
                <img src={Exfimage} alt="Buildings, fully preserved" className='legendImage' />
                <Typography sx={{fontSize:'12px'}} className='menuTitleEx'>Buildings, fully preserved</Typography>
              </div>
              <div className='legendRow'>
                <img src={Expimage} alt="Buildings, partially preserved" className='legendImage' />
                <Typography sx={{fontSize:'12px'}} className='menuTitleEx'>Buildings, partially preserved</Typography>
              </div>
              <div className='legendRow'>
                <img src={Paimage} alt="Building Parts" className='legendImage' />
                <Typography sx={{fontSize:'12px'}} className='menuTitlePa'>Building Parts</Typography>
              </div>
              <div className='legendRow'>
                <img src={Coimage} alt="Contexts" className='legendImage' />
                <Typography sx={{fontSize:'12px'}} className='menuTitleCo'>Contexts</Typography>
              </div>
            </Box>
          </Paper>
          </div>
        </Box>
      </Popper>  */}


      {/* map re-center + 2d   */}
      <Button
        onClick={()=>reCenterMap(map)}
        sx={{
          position:'absolute',
          top:'168px',
          right:'0px',
          backgroundColor:'white',
          zIndex:'2',
          minWidth:'24px!important',
          '&:hover': { backgroundColor: 'white'}
      }} >
        <CenterFocusStrongIcon/>
      </Button>


      {/* map measure */}
      <Button
        ref={anchorMeasure}
        onClick={toggleMeasure}
        sx={{
          position:'absolute',
          top:'387px',
          right:'0px',
          backgroundColor:'white',
          zIndex:'2',
          minWidth:'24px!important',
          '&:hover': { backgroundColor: 'white'}
      }} >
        <StraightenIcon/>
      </Button>

      <div id='measureHook'
        ref={anchorMeasure}
      >
      </div>

      <Popper
        open={openMeasure}
        anchorEl={anchorMeasure.current}
        disableScrollLock={ true }
      >
        <Box  sx={{  bgcolor: 'background.paper' }}>
          <div id='measureBox'>
            <div id='measureTitle'>Measuring Tools</div>
            <Divider sx={{margin:'5px auto', width:'320px'}} variant="middle" />
            <div>
              <Box sx={{marginBottom:'5px'}}><small>Draw a line to measure distance</small></Box>
              <Button variant="outlined" onClick={handleDrawLine}><TimelineIcon/></Button>
              <Button variant="outlined" onClick={handleDrawDelete}><DeleteIcon/></Button>
              <Box sx={{fontSize:'14px', margin:'5px'}}>{measureLine}</Box>
            </div>
            <Divider sx={{margin:'10px 5px 5px 5px', width:'320px'}} variant="middle" />
            <div>
              <Box sx={{marginBottom:'5px'}}><small>Draw a polygon to measure surface area</small></Box>
              <Button variant="outlined" onClick={handleDrawPolygon}><SquareFootIcon/></Button>
              <Button variant="outlined" onClick={handleDrawDelete}><DeleteIcon/></Button>
              <Box sx={{fontSize:'14px', margin:'5px'}}>{measurePolygon}</Box>
            </div>
          </div>
        </Box>
      </Popper>


      {/* layer opacity */}
      <Grid container justifyContent="flex-end" className="map-layer-grid" >
        <Button
          id="map-layers-button"
          onClick={(event)=>handleClick(event, setAnchorEl)}
        >
          <LayersIcon />
        </Button>
        <Menu
          id="map-layer-menu"
          size="small"
          width={300}
          anchorEl={anchorEl}
          open={open}
          onClose={()=>handleClose(setAnchorEl)}
          PaperProps={{
            style: {width: 200, borderRadius:0}
          }}
          MenuListProps={{
            'aria-labelledby': 'map-layer-button',
          }}
          disableScrollLock={ true }
        >
          <Typography  sx={{textAlign:'center'}} gutterBottom>
            Map Layers
          </Typography>
          <MenuItem size="small" className='menuitem' >
            <div>
              <span className='menuTitleEx'>
                Buildings
              </span>
              <div className='menuitemtitle'>
                <Checkbox
                  id="excavation"
                  checked={checkedExcavation}
                  onChange={(event)=>handleLayerVisibleChange(event, setCheckedExcavation, setCheckedPart, setCheckedContext, map)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
            </div>
            <Slider
              name="excavation"
              onChange={(e)=>handleLayerSlider(e ,map, setExcavationSlider, setPartSlider, setContextSlider)}
              size="small"
              value={excavationSlider}
              aria-label="Small"
              valueLabelDisplay="auto"
            />
          </MenuItem>
          <Divider sx={{margin:'5px auto', width:'140px'}} variant="middle" />
          <MenuItem size="small" className='menuitem' >
            <div>
              <span className='menuTitlePa'>
                Building parts
              </span>
              <div className='menuitemtitle'>
                <Checkbox
                  id="part"
                  checked={checkedPart}
                  onChange={(event)=>handleLayerVisibleChange(event, setCheckedExcavation, setCheckedPart, setCheckedContext, map)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
            </div>
            <Slider
              name="part"
              onChange={(e)=>handleLayerSlider(e ,map, setExcavationSlider, setPartSlider, setContextSlider)}
              size="small"
              value={partSlider}
              aria-label="Small"
              valueLabelDisplay="auto"
            />
          </MenuItem>
          <Divider sx={{margin:'5px auto', width:'140px'}} variant="middle" />
          <MenuItem size="small" className='menuitem' >
            <div>
              <span className='menuTitleCo'>
                Contexts
              </span>
              <div className='menuitemtitle'>
                <Checkbox
                  id="context"
                  checked={checkedContext}
                  onChange={(event)=>handleLayerVisibleChange(event, setCheckedExcavation, setCheckedPart, setCheckedContext, map)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
            </div>
            <Slider
              name="context"
              onChange={(e)=>handleLayerSlider(e ,map, setExcavationSlider, setPartSlider, setContextSlider)}
              size="small"
              value={contextSlider}
              aria-label="Small"
              valueLabelDisplay="auto"
            />
          </MenuItem>
        </Menu>
        </Grid>


      {/* map angle */}
      <Grid container justifyContent="flex-end" className="map-layer-grid" >
        <Button
          id="map-angle-button"
          onClick={(event)=>handleAngleClick(event, setAnchorElAngle)}
        >
          <RotateRightIcon/>
        </Button>
        <Menu
          id="map-layer-menu"
          size="small"
          width={300}
          anchorEl={anchorElAngle}
          open={openAngle}
          onClose={()=>handleCloseAngle(setAnchorElAngle)}
          PaperProps={{
            style: {width: 200, borderRadius:0}
          }}
          MenuListProps={{
            'aria-labelledby': 'map-layer-button',
          }}
          disableScrollLock={ true }
        >
          <Typography  sx={{textAlign:'center'}} gutterBottom>
            Map Angle
          </Typography>
          <MenuItem size="small" className='menuitem' >
            <Slider
              name="mapdegrees"
              onChange={(e)=>handleDegreeSlider(e ,map, setDegreesSlider)}
              size="small"
              value={degreesSlider}
              aria-label="Small"
              valueLabelDisplay="auto"
              min={0}
              max={90}
            />
          </MenuItem>
        </Menu>

      </Grid>


      {/* map legend */}
      <Grid container justifyContent="flex-end" className="map-layer-grid" >
        <Button
          id="map-legend-button"
          onClick={(event)=>handleLegendClick(event, setAnchorElLegend)}
        >
          <LegendToggleIcon/>
        </Button>
        <Menu
          id="map-layer-menu"
          size="small"
          width={300}
          anchorEl={anchorElLegend}
          open={openLegend}
          onClose={()=>handleCloseLegend(setAnchorElLegend)}
          PaperProps={{
            style: {width: 200, borderRadius:0}
          }}
          MenuListProps={{
            'aria-labelledby': 'map-layer-button',
          }}
          disableScrollLock={ true }
        >
          <MenuItem size="small" className='menuitem' >
            <Typography  sx={{textAlign:'center'}} gutterBottom>
              Map Legend
            </Typography>
            <div className='legendRow'>
              <img src={Exfimage} alt="Buildings, fully preserved" className='legendImage' />
              <Typography sx={{fontSize:'12px'}} className='menuTitleEx'>Buildings, fully preserved</Typography>
            </div>
            <div className='legendRow'>
              <img src={Expimage} alt="Buildings, partially preserved" className='legendImage' />
              <Typography sx={{fontSize:'12px'}} className='menuTitleEx'>Buildings, partially preserved</Typography>
            </div>
            <div className='legendRow'>
              <img src={Paimage} alt="Building Parts" className='legendImage' />
              <Typography sx={{fontSize:'12px'}} className='menuTitlePa'>Building Parts</Typography>
            </div>
            <div className='legendRow'>
              <img src={Coimage} alt="Contexts" className='legendImage' />
              <Typography sx={{fontSize:'12px'}} className='menuTitleCo'>Contexts</Typography>
            </div>
          </MenuItem>
        </Menu>

      </Grid>

      {/* click drawer */}
      <Drawer
        open={layerDrawer}
        variant="persistent"
        sx={{
          '& .MuiDrawer-root': {
            position: 'absolute',
            top: '65px',
            width:'650px!important'  ,
            // height:'calc(100% - 130px)',
            height:'calc(92vh - 5px)',
          },
          '& .MuiPaper-root': {
            position: 'absolute',
            top: '65px',
            width:'650px!important'  ,
            height:'calc(92vh - 5px)' ,
            overflowY: 'unset'
          },
        }}
      >
        <Box >

        <Button
          onClick={()=>toggleLayerDrawer(setLayerDrawer)}
          sx={{
            position: 'absolute',
            right:'-35px',
            top: '35px',
            backgroundColor: 'white',
            color: 'black',
            visibility:'visible',
            width:'34px!important',
            minWidth:'34px!important',
            '&:hover': {
              backgroundColor: 'white',
              color: 'black',
            }
          }}

        >
          <PlaceIcon />
        </Button>

          <Box className='drawerTopText'>


            <Button sx={{width:'40px', minWidth:'40px', margin:'5px', position:'absolute', left:'5px', marginTop:'1px'}} onClick={(event)=>handleAngleClick(event, setAnchorElCopyrights)} >
              ©
            </Button>

            <Menu
              id="map-layer-menu"
              size="small"
              width={450}
              anchorEl={anchorElCopyrights}
              open={openCopyrights}
              onClose={()=>handleCloseCopyrights(setAnchorElCopyrights)}
              PaperProps={{
                style: {width: 450, borderRadius:0}
              }}
              MenuListProps={{
                'aria-labelledby': 'map-layer-button',
              }}
              disableScrollLock={ true }
            >
              <Typography  sx={{fontSize:'12px'}}>
                <small>This platform contains unpublished data, which are intellectual property of the Sikyon Project. Therefore, publication of any of these data in any form (printed or digital) is not allowed without previous consent by their creators. For any relevant inquiry <a href="/copyrights" > please contact us</a></small>
              </Typography>
            </Menu>

            <Button variant="outlined" sx={{  '&:focus': { backgroundColor: 'lightgrey'} }} style={{textTransform: 'none'}}  onClick={()=>zoomToMarker(map)} >In the point you clicked : </Button>
            <Button variant="outlined" sx={{width:'40px', minWidth:'40px', margin:'5px', position:'absolute', right:'5px', marginTop:'1px'}} onClick={()=>toggleLayerDrawer(setLayerDrawer)} >
              <KeyboardArrowLeftIcon />
            </Button>
          </Box>

          <Box>
            <Grid container spacing={3}  sx={{
              width:'100%' ,
              marginLeft:'0'
            }}>
              <Grid className='drawerColumn' item xs>
                <h4 className='menuTitleEx'>Buildings</h4>
                <small className='drawerTabSmallText' ></small>
                <Box className='drawerColumnContainer'sx={{marginTop:'18px'}} >
                {
                    excavationsList && excavationsList.length>0?
                    <List>
                      {excavationsList.map((e)=>(
                        <ListItem key={e.id} id={'ex_'+e.id}  >
                          <Button variant="outlined" sx={{'&:focus': { backgroundColor: 'lightgrey'} }} style={{textTransform: 'none'}}  onMouseEnter={()=>setClickStateFeatures('excavations', 'excavations-source', e.id, map)} onMouseLeave={()=>unSetClickStateFeatures('excavations', 'excavations-source', e.id, map)} onClick={()=>clickFeatureFromList('excavations-source', e.properties.id, 'ex',false,'','')} >{e.properties.name}</Button>
                          <Button className={highlightExArrowId === e.properties.id ? 'highlightArrow' :''} sx={{minWidth:'5px','&:focus': { backgroundColor: 'lightgrey'} }}  style={{textTransform: 'none'}} onClick={()=>showDrawerList('ex', e.properties.id, setPartsList, setContextsList)} ><ChevronRightIcon/></Button>
                        </ListItem>
                      ))}
                    </List>
                    :
                    ''
                }
                </Box>
              </Grid>
              <Grid className='drawerColumn' item xs={4}>
                <h4 className='menuTitlePa'>Building Parts</h4>
                <small className='drawerTabSmallText' ></small>
                <Box className='drawerColumnContainer' sx={{marginTop:'20px'}}>
                {
                    partsList && partsList.length>0?
                    <List>
                    {partsList.map((e)=>(
                      <ListItem key={e.id} id={'pa_'+e.id}  >
                        <Button disabled={e.properties.id===-1} variant="outlined" sx={{'&:focus': { backgroundColor: 'lightgrey'} }}  style={{textTransform: 'none'}}  onMouseEnter={()=>setClickStateFeatures('excavation_parts', 'excavation_parts-source', e.id, map)} onMouseLeave={()=>unSetClickStateFeatures('excavation_parts', 'excavation_parts-source', e.id, map)} onClick={()=>clickFeatureFromList('excavation_parts-source', e.properties.id, 'pa',false,'','')} >{e.properties.name}</Button>
                        <Button className={highlightPaArrowId === e.properties.id ? 'highlightArrow' :''} disabled={e.properties.id===-1} sx={{minWidth:'5px','&:focus': { backgroundColor: 'lightgrey'} }} style={{textTransform: 'none'}} onClick={()=>showDrawerList('pa', e.properties.id,  setPartsList, setContextsList)} ><ChevronRightIcon/></Button>
                      </ListItem>
                    ))}
                  </List>
                    :
                    ''
                }
                </Box>
              </Grid>
              <Grid className='drawerColumn' item xs>
                <h4 className='menuTitleCo'>Contexts</h4>
                <p className='drawerTabSmallText' >listed and schematically expanded</p>
                <p className='drawerTabSmallText' >surface to bottom, based on their top elevation</p>
                <Box className='drawerColumnContainer drawerColumnContainerCo' >
                {
                  contextsList && contextsList.length>0?
                  <span>
                    <Button disabled={contextsList[0].properties.id===-1} variant="outlined" style={{textTransform: 'none', marginLeft:'15px', backgroundColor:'lightgrey'}}  onClick={()=>prepareExtrudeAll(contextsList, setExtrudeAllClicked)} >
                      {extrudeAllClicked ? 'Remove all' : 'Expand all'}
                    </Button>
                    <List>
                    {contextsList.map((e)=>(
                      <ListItem key={e.id} id={'co_'+e.id}  >
                        <Button disabled={e.properties.id===-1}  variant="outlined" sx={{'&:focus': { backgroundColor: 'lightgrey'} }} style={{textTransform: 'none'}}  onMouseEnter={()=>setClickStateFeatures(false, 'contexts-source', e.properties.id, map)} onMouseLeave={()=>unSetClickStateFeatures(false, 'contexts-source', e.properties.id, map)} onClick={()=>clickFeatureFromList('contexts-source', e.properties.id, 'co',false,'','')} >{e.properties.no}</Button>
                      </ListItem>
                    ))}
                  </List>
                  </span>
                  :
                  ''
                }
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

      </Drawer>

      {/* goto drawer */}
      <Drawer
        open={gotoDrawer}
        variant="persistent"
        sx={{
          '& .MuiDrawer-root': {
            position: 'absolute',
            top: '65px',
            width:'650px!important'  ,
            height:'calc(92vh - 5px)' ,
          },
          '& .MuiPaper-root': {
            position: 'absolute',
            top: '65px',
            width:'650px!important'  ,
            height:'calc(92vh - 5px)' ,
            overflowY: 'unset'
          },
        }}

      >
        <Box >

        <Button
          onClick={()=>toggleGotoDrawer(setGotoDrawer)}
          sx={{
            position: 'absolute',
            right:'-35px',
            top: '105px',
            backgroundColor: 'white',
            color: 'black',
            visibility:'visible',
            width:'34px!important',
            minWidth:'34px!important',
            '&:hover': {
              backgroundColor: 'white',
              color: 'black',
            }
          }}
          className={layerDrawer ? 'searchDrawerButtonVisible' :''}
        >
          <SearchIcon />
        </Button>

          <Box className='drawerTopText'>
            <Button sx={{width:'40px', minWidth:'40px', margin:'5px', position:'absolute', left:'5px', marginTop:'1px'}} onClick={(event)=>handleAngleClick(event, setAnchorElCopyrights)} >
              ©
            </Button>

            <Button variant="outlined" sx={{width:'40px', minWidth:'40px', margin:'5px', position:'absolute', right:'5px', marginTop:'-9px'}} onClick={()=>toggleGotoDrawer(setGotoDrawer)} >
              <KeyboardArrowLeftIcon />
            </Button>
          </Box>

          <Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={valueSearchTab} onChange={handleChangeSearchTab} aria-label="basic tabs example"
                scrollButtons
                allowScrollButtonsMobile
                centered
              >
              {/* variant="scrollable" scrollButtons="auto" */}
                <Tab label="Buildings" {...a11yProps(0)} />
                <Tab label="Contexts" {...a11yProps(1)} />
              </Tabs>
            </Box>

            <TabPanel value={valueSearchTab} index={0}>
              {/* buildings */}

              {
                searchCategoriesInfoSelector.success!==true?
                <Box sx={{display:'inline-block', textAlign:'center', margin:'0 auto', width:'100%', padding:'5px'}}>Loading search components</Box>
                :
                searchCategoriesInfoSelector.data.buildings && searchCategoriesInfoSelector.data.buildings.length>0?

                <Box
                  sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      p: 1,
                      m: 1,
                      margin:'4px 0',
                      padding:'4px 0'
                    }}
                >

                  <Autocomplete
                    value={buildingsAutocompleteVal}
                    id="combo-box-buildings"
                    options={searchCategoriesInfoSelector.data.buildings}
                    sx={{ width: 400 , margin:'0 10px'}}
                    size='small'
                    onChange={(event, value)=>handleBuildingsDropDownChange(event, value, setSearchParams)}
                    renderInput={(params) => <TextField {...params} label="Select a building" />}
                  />

                  <Button
                    size='small'
                    variant="outlined"
                    sx={{ margin:'0 10px' }}
                    style={{textTransform: 'none'}}
                    loading={loadingVisible}
                    onClick={()=>gotoBuilding(searchParams)}
                  >
                    Go
                    <CircularProgress sx={{visibility:'hidden', position:'fixed', color:'lightgrey'}} className={loadingVisible ? 'visibleSearchLoading' :''} />
                  </Button >

                </Box>
                :
                'loading search bar'
              }

            </TabPanel>

            <TabPanel value={valueSearchTab} index={1}>
              {/* contexts */}
              {
                searchCategoriesInfoSelector.success!==true?
                <Box sx={{display:'inline-block', textAlign:'center', margin:'0 auto', width:'100%', padding:'5px'}}>Loading search components</Box>
                :
                searchCategoriesInfoSelector.data.contexts && searchCategoriesInfoSelector.data.contexts.length>0?

                <Box
                  sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      p: 1,
                      m: 1,
                      margin:'4px 0',
                      padding:'4px 0'
                    }}
                >

                <Autocomplete
                  value={contextsAutocompleteVal}
                  id="combo-box-contexts"
                  options={searchCategoriesInfoSelector.data.contexts}
                  getOptionLabel={(option) => option.label.toString()}
                  sx={{ width: 400 , margin:'0 10px' }}
                  size='small'
                  onChange={(event, value)=>handleContextsDropDownChange(event, value, setSearchParams)}
                  renderInput={(params) => <TextField {...params} label="Select a Context" />}
                />

                  <Button
                    size='small'
                    variant="outlined"
                    sx={{ margin:'0 10px' }}
                    style={{textTransform: 'none'}}
                    loading={loadingVisible}
                    onClick={()=>gotoContext(searchParams)}
                  >
                    Go
                    <CircularProgress sx={{visibility:'hidden', position:'fixed', color:'lightgrey'}} className={loadingVisible ? 'visibleSearchLoading' :''} />
                  </Button >

                </Box>
                :
                'loading search bar'
              }
            </TabPanel>

          </Box>

        </Box>

      </Drawer>

      {/* search results drawer */}
      {/* <Drawer
        open={resultsDrawer}
        variant="persistent"
        sx={{
          '& .MuiDrawer-root': {
            position: 'absolute',
            top: '121px',
            width:'650px!important'  ,
            height:'calc(92vh - 51px)' ,
          },
          '& .MuiPaper-root': {
            position: 'absolute',
            top: '121px',
            width:'650px!important'  ,
            height:'calc(92vh - 51px)' ,
            overflowY: 'unset'
          },
        }}

      >
        <Box >

        <Button
          onClick={()=>toggleResultsDrawer(setResultsDrawer)}
          sx={{
            position: 'absolute',
            right:'-35px',
            top: '105px',
            backgroundColor: 'white',
            color: 'black',
            visibility:'visible',
            width:'34px!important',
            minWidth:'34px!important',
            '&:hover': {
              backgroundColor: 'white',
              color: 'black',
            }
          }}
          className={layerDrawer ? 'searchDrawerButtonVisible' :''}
        >
          <ListIcon />
        </Button>

          <Box className='drawerTopText'>
          <Button sx={{width:'40px', minWidth:'40px', margin:'5px', position:'absolute', left:'5px', marginTop:'1px'}} onClick={(event)=>handleAngleClick(event, setAnchorElCopyrights)} >
              ©
            </Button>
            Search results :
            <Button variant="outlined" sx={{width:'40px', minWidth:'40px', margin:'5px', position:'absolute', right:'5px', marginTop:'-9px'}} onClick={()=>toggleResultsDrawer(setResultsDrawer)} >
              <KeyboardArrowLeftIcon />
            </Button>
          </Box>

          <Box sx={{display:'none'}} className={'drawerTopText ' + (resultsMessageVisible ? 'resultsMessageVisibleStyle' :'')}>
            {resultsMessage}
          </Box>

          <Box>
            <Grid container spacing={3}  sx={{
              width:'100%' ,
              marginLeft:'0'
            }}>
              <Grid className='drawerColumn' item xs>
                <h4 className='menuTitleEx'>Buildings</h4>
                <small className='drawerTabSmallText' ></small>
                <Box className='drawerColumnContainer'>
                {
                    excavationsResultsList && excavationsResultsList.length>0?
                    <List>
                    {excavationsResultsList.map((e)=>(
                      <ListItem key={e.id} id={'ex_'+e.id}  >
                        <Button variant="outlined" style={{textTransform: 'none'}}  onMouseEnter={()=>setClickStateFeatures('excavations', 'excavations-source', e.id, map)} onMouseLeave={()=>unSetClickStateFeatures('excavations', 'excavations-source', e.id, map)} onClick={()=>clickFeatureFromList('excavations-source', e.properties.id, 'ex')} >{e.properties.name}</Button>
                        <Button className={highlightExSearchArrowId === e.properties.id ? 'highlightArrow' :''} sx={{minWidth:'5px'}}  style={{textTransform: 'none'}} onClick={()=>showDrawerResultList('ex', e.properties.id,  setPartsResultsList, setContextsResultsList)} ><ChevronRightIcon/></Button>
                      </ListItem>
                    ))}
                  </List>
                    :
                    ''
                }
                </Box>
              </Grid>
              <Grid className='drawerColumn' item xs={4}>
                <h4 className='menuTitlePa'>Building Parts</h4>
                <small className='drawerTabSmallText' ></small>
                <Box className='drawerColumnContainer'>
                {
                    partsResultsList && partsResultsList.length>0?
                    <List>
                    {partsResultsList.map((e)=>(
                      <ListItem key={e.id} id={'pa_'+e.id}  >
                        <Button disabled={e.properties.id===-1} variant="outlined" style={{textTransform: 'none'}}  onMouseEnter={()=>setClickStateFeatures('excavation_parts', 'excavation_parts-source', e.id, map)} onMouseLeave={()=>unSetClickStateFeatures('excavation_parts', 'excavation_parts-source', e.id, map)} onClick={()=>clickFeatureFromList('excavation_parts-source', e.properties.id, 'pa')} >{e.properties.name}</Button>
                        <Button className={highlightPaSearchArrowId === e.properties.id ? 'highlightArrow' :''} disabled={e.properties.id===-1} sx={{minWidth:'5px'}}  style={{textTransform: 'none'}} onClick={()=>showDrawerResultList('pa', e.properties.id,  setPartsResultsList, setContextsResultsList)} ><ChevronRightIcon/></Button>
                      </ListItem>
                    ))}
                  </List>
                    :
                    ''
                }
                </Box>
              </Grid>
              <Grid className='drawerColumn' item xs>
                <h4 className='menuTitleCo'>Contexts</h4>
                <small className='drawerTabSmallText' >surface to bottom</small>
                <Box className='drawerColumnContainer drawerColumnContainerCo' >
                {
                  contextsResultsList && contextsResultsList.length>0?
                  <span>
                    <Button disabled={contextsResultsList[0].properties.id===-1} variant="outlined" style={{textTransform: 'none', marginLeft:'15px', backgroundColor:'lightgrey'}}  onClick={()=>prepareExtrudeAllSearch(contextsResultsList, setExtrudeAllClickedSearch)} >
                      {extrudeAllClickedSearch ? 'Remove all' : 'Expand all'}
                    </Button>
                    <List>
                      {contextsResultsList.map((e)=>(
                        <ListItem key={e.id} id={'co_'+e.id}  >
                          <Button disabled={e.properties.id===-1} variant="outlined" style={{textTransform: 'none'}}  onMouseEnter={()=>setClickStateFeatures(false, 'contexts-source', e.properties.id, map)} onMouseLeave={()=>unSetClickStateFeatures(false, 'contexts-source', e.properties.id, map)} onClick={()=>clickFeatureFromList('contexts-source', e.properties.id, 'co')} >{e.properties.no}</Button>
                        </ListItem>
                      ))}
                    </List>
                  </span>
                    :
                    ''
                }
                </Box>
              </Grid>
            </Grid>
          </Box>

        </Box>

      </Drawer> */}

      {/* excavation / Parts  / contexts info   */}
      <Drawer
        open={featuresInfoDrawer}
        variant="persistent"
        sx={{
          '& .MuiDrawer-root': {
            position: 'absolute',
            top: '65px',
            width:'685px!important'  ,
            height:'calc(92vh - 5px)' ,
          },
          '& .MuiPaper-root': {
            position: 'absolute',
            top: '65px',
            width:'685px!important' ,
            height:'calc(92vh - 5px)' ,
          },
        }}
      >
        <Box >
          <div>
            <Grid container justifyContent="flex-end" id="map-layer-drawer" >
              <Button variant="outlined" sx={{width:'40px', minWidth:'40px', margin:'5px'}} onClick={()=>toggleFeaturesInfoDrawer(setFeaturesInfoDrawer)} >
                <KeyboardArrowLeftIcon />
              </Button>
            </Grid>
          </div>
          <Box id='featureInfoBase' >
          </Box>
        </Box>

      </Drawer>

      {/* stratigrapjy context  info           */}
      <Drawer
        open={stratigraphyInfoDrawer}
        variant="persistent"
        sx={{
          '& .MuiDrawer-root': {
            position: 'absolute',
            top: '65px',
            width:'685px!important'  ,
            height:'calc(92vh - 5px)' ,
          },
          '& .MuiPaper-root': {
            position: 'absolute',
            top: '65px',
            width:'685px!important' ,
            height:'calc(92vh - 5px)' ,
          },
        }}
      >
        <Box >
          <div>
            <Grid container justifyContent="flex-end" id="map-layer-drawer" >
              <Button variant="outlined" sx={{width:'240px', minWidth:'40px', margin:'5px'}} onClick={()=>toggleStratigraphyInfoDrawer(setStratigraphyInfoDrawer)} >
                Back to Stratigraphy<KeyboardArrowLeftIcon />
              </Button>
            </Grid>
          </div>
          <Box id='stratigraphyInfoBase' >
          </Box>
        </Box>

      </Drawer>


      <Popper
        open={openExPop}
        onClose={handleCloseExPop}
        anchorEl={anchorRefExPop.current}
        // anchorPosition={{ top: 10, left: 10 }}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left',
        // }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left',
        // }}
      >
        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
          <small id='expoptext'>
          Hold Control button and use mouse to change viewing angle
          <FormControlLabel
            className={acceptCookiesValue === 'r' ? 'hideExPop' : ''}
            id='expoptextClick'
            control={
              <Checkbox
                checked={dontShowAgainExPop}
                onChange={handleCheckboxChangeExPop}
                name="dontShowAgain"
                color="primary"
              />
            }
            label="Don't show again"
          />
          <Button size='small' variant="outlined" onClick={handleCloseExPop} color="primary">
            OK
          </Button>
          </small>
        </Box>
      </Popper>

      <div
        ref={anchorRefExPop}
        style={{
          position:'absolute',
          bottom: '0',
          right:'0',
          width: '10px',
          height: '20px'
        }}
        id='exPopHook'
      >
      </div>


      <Popper
        open={openExInfo}
        anchorEl={anchorRefExInfo.current}
        // anchorPosition={{ top: 10, left: 10 }}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left',
        // }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left',
        // }}
      >
        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
          <div>
            {exInfoText}
          </div>
        </Box>
      </Popper>

      <div
        ref={anchorRefExInfo}
        id='exInfoHook'
      >
      </div>



      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
        open={openBackDrop}
      >
        <div className='backdropContent'>
          <div className='backdropContent'>
            Loading Map Data
          </div>
          <div className='backdropContent'>
            <CircularProgress color="inherit" />
          </div>
        </div>
      </Backdrop>



      {/* <Dialog
        open={openSearchDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Press Control button and use mouse in map to change viewing angles
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>  */}




      <div ref={mapContainer} className="map-container"  ></div>
    </div>
  )

}

export default Map;


// [1]
// having a return statement at the beginning of a useEffect does not stop React from knowing about state changes and re-renders. The return statement is used to prevent the execution of the code inside the useEffect on certain conditions, but it does not stop React from re-rendering the component or from running the useEffect again.

// React will re-run the useEffect whenever any of the values in the dependencies array change. If the dependencies array is empty, React will re-run the useEffect on every render.

// In the case of your useEffect, the return statement is used to prevent the initialization of the map when it's already been initialized and the map.current exists. But it does not stop React from running the useEffect and updating the partsList state when the state value changes.

// It's important to note that if you are using a state variable inside the useEffect and you don't include it as a dependency, React will run the effect on every render, which could cause unexpected behavior or poor performance.
