import {useEffect, useState} from 'react';   
import PropTypes from 'prop-types'; 
import Typography from '@mui/material/Typography';  
import Box from '@mui/material/Box'; 
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react'  
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
 

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function TabPanelSeason(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanelSeason.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
} 

function a11yPropsSeason(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}  


function replaceImagePlaceholders(seasons, images) { 
  let baseSrc= "api/"  ;
  if (process.env.REACT_APP_ENV === 'dev') {
    baseSrc= "http://localhost:3500/"
  }
  
  let formatted = []
  seasons.forEach(season => {
    let text = season.description;
    images.forEach(image => {
      if (season.id === image.season) {
        let imgEl = `<img class='seasonimage' src="${baseSrc+image.path}" alt="${image.caption}" /> <div variant="caption" class='seasoncaption'>${image.caption}</div>  `;   
        text = text.replace(image.replacename, imgEl) 
      }
    });
    formatted.push({
      id:season.id,
      description:text,
      year:season.year
    })
  }); 

  return formatted;
} 

const ExcavationInfo = ({data}) => {
  const [valueTab, setValueTab] = useState(0);
  const [valueTabSeason, setValueTabSeason] = useState(0);
  const [formattedText, setFormattedText] = useState([]);
  const [dialogInfo, setDialogInfo] = useState({
    open:false,
    path:'',
    caption:''
  });  
  
  const handleDescriptionClick = (event) => {
    const target = event.target; 
    if (target.classList.contains('seasonimage')) { 
      const imageUrl = target.src; 
      
      setDialogInfo(prevState => ({
        ...prevState,
        open:true ,
        path:imageUrl,
        caption:target.alt
      })) 
    }
  };

  const handleCloseDialog = () =>{
    setDialogInfo(prevState => ({
      ...prevState,
      open:false
    }))
  }


  const handleChangeTab = (e,newValue) => {  
    setValueTab(newValue); 
  };

  const handleChangeTabSeason = (e,newValue) => {  
    setValueTabSeason(newValue); 
  };

  useEffect(() => { 
    setFormattedText(replaceImagePlaceholders(data.info.data.seasons, data.info.data.images));
  }, [data]);

  return (
    <div> 
      <Typography component='div' variant='h5' sx={{textAlign:'center'}} >{data.info.data.name}</Typography> 

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example" centered>
          <Tab label="Description" {...a11yProps(0)} />
          <Tab label="Excavation Seasons" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={valueTab} index={0}>
        <Box>   
          <div className="featureInfoLongestText" dangerouslySetInnerHTML={{ __html: data.info.data.description}}> 
            {/* {data.info.data.description} */}
          </div>
        </Box> 
      </TabPanel>
      <TabPanel value={valueTab} index={1}  >  
        {data.info.data.seasons.length > 0 ?
          <span>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={valueTabSeason} onChange={handleChangeTabSeason} aria-label="basic tabs example" centered scrollButtons>
                  {data.info.data.seasons.map((e,i)=>( 
                    <Tab key={'season_tab_'+e.year} label={e.year} {...a11yPropsSeason(i)} />
                  ))} 
                </Tabs>
              </Box>
                {
                  formattedText.length > 0 &&   
                  formattedText.map((e,i)=>( 
                    <TabPanel key={'season_text_'+e.year} value={valueTabSeason} index={i} >
                      <Box>     
                        <div onClick={handleDescriptionClick} className="featureInfoLongestText" style={{whiteSpace:'pre-wrap'}} dangerouslySetInnerHTML={{ __html: e.description}}> 
                        </div>
                      </Box> 
                    </TabPanel>
                  ))
                } 
            </span> 
           :
           <Box sx={{display:'inline-block', textAlign:'center', margin:'0 auto', width:'100%', padding:'5px'}}>No Seasons Found</Box>
           
          }
      </TabPanel> 

      {/* <Dialog open={dialogInfo.open} sx={{width:'80vw', margin:'0 auto'}}>
        <Box sx={{width:'100%', height:'100%', margin:'5px'}} >
          <img 

            src={dialogInfo.path}
            alt={dialogInfo.caption} 
          />

        </Box>
      </Dialog> */}

      <Dialog open={dialogInfo.open}   maxWidth="xl" fullWidth sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <DialogContent  >
        <DialogTitle >
          
        </DialogTitle>
        <div style={{ textAlign: 'center' }}>
          <img src={dialogInfo.path} alt={dialogInfo.caption}  style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
        <Typography variant="caption">{dialogInfo.caption}</Typography>
      </DialogContent>
      <IconButton  sx={{position:'absolute', top:'0', right:'15px'}} onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton> 
    </Dialog>
                

    </div>
  )
}

export default ExcavationInfo