import {useState} from 'react';   
import PropTypes from 'prop-types'; 
import Typography from '@mui/material/Typography';  
import Box from '@mui/material/Box'; 
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';  

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
} 
 

const PartInfo = ({data, setClickStateFeaturesPartInfo, unSetClickStateFeaturesPartInfo, clickFeatureFromListPartInfo}) => {
  const [valueTab, setValueTab] = useState(0);
  const handleChangeTab = (e,newValue) => {  
    setValueTab(newValue); 
  };
  return (
    <div> 
      <Typography component='div' variant='h5' sx={{textAlign:'center'}} >{data.info.data.name}</Typography>   
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example" centered>
          <Tab label="Description" {...a11yProps(0)} />
          <Tab label="Function" {...a11yProps(1)} />
          <Tab label="Contexts" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={valueTab} index={0}>
        <Box>
          {
            data.info.data.description != null && data.info.data.description != '' && data.info.data.description != 'null' && data.info.data.description.length > 0 ? 
            <div style={{whiteSpace:'pre-wrap'}} className="featureInfoLongestText">
              {data.info.data.description}
            </div>
            :
            <Box sx={{display:'inline-block', textAlign:'center', margin:'0 auto', width:'100%', padding:'5px'}}>No Description Added Yet</Box>
          }  
        </Box> 
      </TabPanel>
      <TabPanel value={valueTab} index={1}>
        <Box>
        {
          data.info.data.usage != null && data.info.data.description != '' && data.info.data.description != 'null' && data.info.data.usage.length > 0 ? 
          <div style={{whiteSpace:'pre-wrap'}} className="featureInfoLongestText">
            {data.info.data.usage}
          </div> 
          : 
          <Box sx={{display:'inline-block', textAlign:'center', margin:'0 auto', width:'100%', padding:'5px'}}>No Usage Added Yet</Box>
        }  
        </Box>     
      </TabPanel> 
      <TabPanel value={valueTab} index={2}>
        <Box>
        {
          data.info.data.contexts != null && data.info.data.contexts != '' && data.info.data.contexts != 'null' && data.info.data.contexts.length > 0 ? 
          <div style={{whiteSpace:'pre-wrap'}} className="featureInfoLongestText">
            {data.info.data.contexts.length} contexts, geospatially contained within or crossing {data.info.data.name}
            <p><small>listed according to their top elevation </small></p>
            

            <List> 
              {data.info.data.contexts.map((e)=>( 
                <ListItem key={e.id} id={'co_'+e.id}  >
                  <Button  variant="outlined" sx={{'&:focus': { backgroundColor: 'lightgrey'} }} style={{textTransform: 'none'}}  onMouseEnter={()=>setClickStateFeaturesPartInfo(e.id)} onMouseLeave={()=>unSetClickStateFeaturesPartInfo(e.id)} onClick={()=>clickFeatureFromListPartInfo(e.id, data.info.data.name)} >{e.no}</Button>
                </ListItem>  
              ))}
            </List>

          </div> 
          : 
          <Box sx={{display:'inline-block', textAlign:'center', margin:'0 auto', width:'100%', padding:'5px'}}>No contexts geospatially contained within or crossing {data.info.data.name} </Box>
        }  
        </Box>     
      </TabPanel> 
                

    </div>
  )
}

export default PartInfo