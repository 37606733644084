import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";  

let FEATURE_URL = 'api/search'
if (process.env.REACT_APP_ENV === 'dev') {
   FEATURE_URL = 'http://localhost:3500/search'
} 

//status : idle, loading, succeeded, failed
const initialState = {
    categories:{
      info:'a',
      status:'idle',
      error:'null'
    },
    results:{
      info:'a',
      status:'idle',
      error:'null'
    }
}

export const fetchSearchCategories = createAsyncThunk('search/fetchSearchCategories', async () => { 
    const response = await axios.get(FEATURE_URL+'/categories') 
    return response.data
})

export const fetchSearchResults = createAsyncThunk('search/fetchSearchResults', async (captchaParams) => { 
  const headers = {
      "Content-Type": "text/json"
  }; 
  const response = await axios.post(FEATURE_URL+'/results', {...captchaParams});  
  return response.data
})

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: { 
        // getASearchCategory:{
        //     reducer(state, action){
        //         //do something with the id 
        //         console.log('');
        //     },
        //     // prepare(category, id){
        //     //     return{
        //     //         payload:{
        //     //             category,
        //     //             id
        //     //         }
        //     //     }
        //     // }
        // }
    },
    extraReducers(builder){
        builder
        .addCase(fetchSearchCategories.pending, (state, action) => {
            state.categories.status = 'loading'
        })
        .addCase(fetchSearchCategories.rejected, (state, action) => {
            state.categories.status = 'failed';  
        })
        .addCase(fetchSearchCategories.fulfilled, (state, action) => { 
            state.categories.status = 'succeeded';
            state.categories.info = action.payload; 
        })
        .addCase(fetchSearchResults.pending, (state, action) => {
          state.results.status = 'loading'
        })
        .addCase(fetchSearchResults.rejected, (state, action) => {
            state.results.status = 'failed';  
        })
        .addCase(fetchSearchResults.fulfilled, (state, action) => { 
            state.results.status = 'succeeded';
            state.results.info = action.payload; 
        })
    }
  }) 
   
  export const searchCategoriesInfo = (state) => state.search.categories.info;
  export const searchCategoriesError = (state) => state.search.categories.error;
  export const searchCategoriesStatus = (state) => state.search.categories.status;

  export const searchResultsInfo = (state) => state.search.results.info;
  export const searchResultsError = (state) => state.search.results.error;
  export const searchResultsStatus = (state) => state.search.results.status;

  export const {getASearchCategory} = searchSlice.actions
  export default searchSlice.reducer