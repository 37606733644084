//rafce 
import * as React from 'react';  
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';  
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography'; 
import Grid from '@mui/material/Grid'; 
import Logo from "../assets/intro.png";
import {Link} from 'react-router-dom'

const Header = () => { 

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header >  
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="sticky" sx={{backgroundColor:'rgb(65,65,66)'}} >
          <Toolbar >  
          <Link to="/"  >
            <img src={Logo} alt="example" style={{height : '60px', margin: '0 10px -4px 0' }} />
          </Link> 
            <Typography component={Link} to="/" variant='body1' sx={{minWidth:'112px', color:'white', textDecoration:'none', cursor:'pointer'}} >Sikyon Excavation</Typography> 
            <Grid container justifyContent="flex-end" >
              <IconButton
                size="small"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleClick}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            </Grid>  

          </Toolbar> 
        </AppBar>
      </Box>   
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {borderRadius:0}  
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disableScrollLock={ true }
      >
        <MenuItem component={Link} to="/" >
          Home
        </MenuItem>
        <MenuItem component={Link} to="/map">
          Excavations
        </MenuItem>  
        <MenuItem component={Link} to="/about">
          About
        </MenuItem> 
        <MenuItem component={Link} to="/copyrights">
          Copyrights
        </MenuItem> 
      </Menu>
    </header>
  )
}

export default Header
