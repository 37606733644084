import Grid from '@mui/material/Grid';   
import Chart from 'react-apexcharts'
import Button from '@mui/material/Button';  
import Divider from '@mui/material/Divider';  
import {useEffect, useState} from 'react';   
import PropTypes from 'prop-types'; 
import Typography from '@mui/material/Typography';  
import Box from '@mui/material/Box'; 
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react' 
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

let extrudeStratigraphy = [];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
} 


//data={} stratigraphy={} stratigraphyType={} stratigraphyName={} prepareExtrudeContext={} setClickStateFeaturesPartInfo={} unSetClickStateFeaturesPartInfo={} clickFeatureFromListPartInfo={}
const ContextInfo = ({data, stratigraphy, stratigraphyType,stratigraphyName, prepareExtrudeContext, setClickStateFeaturesPartInfo, unSetClickStateFeaturesPartInfo, clickFeatureFromListPartInfo}) => {
  const [valueTab, setValueTab] = useState(0);
  const [weightChart, setWeightChart] = useState({data:'', sum:''});
  const [countChart, setCountChart] = useState({data : '', sum:'' });  
  const [zooChartApex, setZooChartApex] = useState({data : '', sum:'' }); 
  const [extrudeClicked, setExtrudeClicked] = useState(false);
  const [extrudeAllClicked, setExtrudeAllClicked] = useState(false);
  const handleChangeTab = (e,newValue) => {  
    setValueTab(newValue); 
  };

  const prepareExtrudeContexts = (no, setExtrudeClicked) =>{ 

    console.log('prepareExtrudeContexts before send to map ', no);
    prepareExtrudeContext(no, extrudeClicked)
    setExtrudeClicked(!extrudeClicked)
  }

  
  const prepareExtrudeStratigraphicContexts = () =>{
    extrudeStratigraphy = []
    data.info.data.relationships.forEach(e => {
      console.log('data.info.data.relationships lop for extrude ', e);  
      extrudeStratigraphy.push(e.related_context)
    }); 
    extrudeStratigraphy.push(data.info.data.no)
    console.log('final extrudeStratigraphy after llop ', extrudeStratigraphy);  
    prepareExtrudeContext(extrudeStratigraphy, extrudeAllClicked)
    setExtrudeAllClicked(!extrudeAllClicked)
  } 



  useEffect(() => {   

    console.log('context info data ', data.info.data);

    if (!data.info.data || data.info.data === undefined || data.info.data === 'undefined') {
      setWeightChart(prevState => ({
        ...prevState,
        data: false
      }))   
      setCountChart(prevState => ({
        ...prevState,
        data: false
      }))  
      setZooChartApex(prevState => ({
        ...prevState,
        data: false
      }))  
      return
    } 

    if (
      data.info.data.pottery_wt === null || data.info.data.pottery_wt === '' || !data.info.data.pottery_wt ||
      data.info.data.pottery_wt.fwtorig === null || data.info.data.pottery_wt.fwtorig === '' || !data.info.data.pottery_wt.fwtorig ||
      data.info.data.pottery_wt.cswtorig === null || data.info.data.pottery_wt.cswtorig === ''  || !data.info.data.pottery_wt.cswtorig || 
      data.info.data.pottery_wt.cwtorig === null || data.info.data.pottery_wt.cwtorig === '' || !data.info.data.pottery_wt.cwtorig 
    ) {
      setWeightChart(prevState => ({
        ...prevState,
        data: false
      }))      
    }
    else{
      let sum = Number(data.info.data.pottery_wt.fwtorig) + Number(data.info.data.pottery_wt.cswtorig) + Number(data.info.data.pottery_wt.cwtorig)
      sum = Math.round(sum * 100) / 100 
      setWeightChart(prevState => ({
        ...prevState,
        data: {
          options : {
            labels: ['Fine', 'Coarse', 'Cooking'],
            chart: {
              id: 'weight'
            },
            legend:{
              offsetX:15
            }
          },
          series: [data.info.data.pottery_wt.fwtorig,data.info.data.pottery_wt.cswtorig,data.info.data.pottery_wt.cwtorig] 
        },
        sum:sum
      }))

    }

    if (
      data.info.data.pottery_wt === null || data.info.data.pottery_wt ==='' || !data.info.data.pottery_wt || 
      data.info.data.pottery_wt.forigct === null || data.info.data.pottery_wt.forigct ==='' || !data.info.data.pottery_wt.forigct || 
      data.info.data.pottery_wt.csorigct === null || data.info.data.pottery_wt.csorigct ==='' || !data.info.data.pottery_wt.csorigct || 
      data.info.data.pottery_wt.corigct === null || data.info.data.pottery_wt.corigct ==='' || !data.info.data.pottery_wt.corigct  
    ) {
      setCountChart(prevState => ({
        ...prevState,
        data: false
      }))      
    }
    else{
      let sum = Number(data.info.data.pottery_wt.forigct) + Number(data.info.data.pottery_wt.csorigct) + Number(data.info.data.pottery_wt.corigct)
      sum = Math.round(sum * 100) / 100   
      setCountChart(prevState => ({
        ...prevState,
        data: {
          options : {
            labels: ['Fine', 'Coarse', 'Cooking'],
            chart: {
              id: 'count'
            },
            legend:{
              offsetX:15
            }
          },
          series: [data.info.data.pottery_wt.forigct, data.info.data.pottery_wt.csorigct, data.info.data.pottery_wt.corigct] 
        },
        sum:sum
      }))

    } 

    if (
      data.info.data.zoo === null || data.info.data.zoo ==='' || !data.info.data.zoo || 
      data.info.data.zoo.chart === null || data.info.data.zoo.chart ==='' || !data.info.data.zoo.chart || 
      data.info.data.zoo.chart.data === null || data.info.data.zoo.chart.data ==='' || !data.info.data.zoo.chart.data
    ) { 
      setZooChartApex(prevState => ({
        ...prevState,
        data: false
      }))    
    }
    else{ 
      let a = []
      data.info.data.zoo.series.forEach(element => {        
        a.push(Number(element))  
      }); 
      setZooChartApex(prevState => ({
        ...prevState,
        data: {
          options : {
            labels: data.info.data.zoo.labels,
            chart: {
              id: 'zoo'
            },
            legend:{
              offsetX:10
            }
          },
          series: a
        },
        sum:data.info.data.zoo.chart.sum
      }))
    }  

    
  }, [data.info.data]) 

  return (
    <div>  
      {
        stratigraphy===true?
        <Typography component='div' variant='h7' sx={{textAlign:'center', marginTop:'15px'}} > <b> Context No. {data.info.data.no}, part of 
        
          {stratigraphyType==='pa'?
          <span> {stratigraphyName} Stratigraphy</span>
          :
          <span> Context {stratigraphyName} Stratigraphy</span>
          }
        </b>
        </Typography>    
        :
        <Typography component='div' variant='h5' sx={{textAlign:'center'}} >Context No. {data.info.data.no}</Typography>    
      }
      

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example"  
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
        {/* variant="scrollable" scrollButtons="auto" */}
          <Tab label="Description" {...a11yProps(0)} /> 
          <Tab label="Stratigraphy" {...a11yProps(1)} /> 
          <Tab label="Pottery" {...a11yProps(2)} />
          <Tab label="Inventory" {...a11yProps(3)} /> 
          <Tab label="other findings" {...a11yProps(4)} /> 
          <Tab label="coins" {...a11yProps(5)} />           
          <Tab label="faunal remains" {...a11yProps(6)} /> 
          <Tab label="floral remains" {...a11yProps(7)} /> 
        </Tabs>
      </Box>


      <TabPanel value={valueTab} index={0}> 
      {/* description */}
        <Box>   
        <div  >
          <Button variant="outlined"  style={{textTransform: 'none'}}  sx={{ textAlign: 'center', margin: '0 auto', display:'block' }} onClick={()=>prepareExtrudeContexts([data.info.data.no], setExtrudeClicked)}>
            {extrudeClicked ? 'Remove' : 'Expand'}
          </Button>
        </div>  
        <div className="featureInfoLongestText">
            <Box className="featureInfoText">
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <div>
                    <i>type : </i><b>{data.info.data.type}</b> , <span>
                    <i>period : </i></span><b>
                    {data.info.data.period!=='' && data.info.data.period!==null && data.info.data.period!=='null'?
                    data.info.data.period
                    :
                    ' - ' } </b>
                  </div> 
                  <i>date : </i><b>{data.info.data.date}</b>
                  <span> , <i>dated by : {data.info.data.dated_by} </i>  </span> 
                </Grid>
                <Grid item xs={4}>
                  <div>
                    <i>elevation top : </i><b>{data.info.data.elevtop} m.</b>
                  </div>
                    <i>elevation bottom : </i><b>{data.info.data.elevbtm} m.</b>
                </Grid>
              </Grid>

            </Box> 
            <div className='featureInfoAirText'>
              <div className="featureInfoLongText" style={{whiteSpace:'pre-wrap', borderTop:'1px solid lightgrey', borderBottom:'1px solid lightgrey', padding:'5px 0'}} dangerouslySetInnerHTML={{ __html: data.info.data.excnotes}}></div> 
            </div>

            
            {
            data.info.data.structure !== false &&
              <Box>
                <Divider sx={{margin:'10px auto', width:'100%'}} variant="middle" /> 
                <div className='featureInfoAirText'>  
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <div><i>materials : </i><b>{data.info.data.structure.materials}</b></div> 
                        <div><i>material finish : </i><b>{data.info.data.structure.material_finish}</b></div> 
                        <div><i>material construction : </i><b>{data.info.data.structure.material_construction}</b></div> 
                      </Grid>
                      <Grid item xs={4}>
                        <div><i>bonding : </i><b>{data.info.data.structure.material_bonding}</b></div> 
                        <div><i>features : </i><b>{data.info.data.structure.features}</b></div>    
                      </Grid>
                    </Grid>
                    <div><i>material size : </i><b>{data.info.data.structure.material_size}</b></div> 
                    <div><i>material dimensions : </i><b>{data.info.data.structure.dimensions}</b></div> 
                </div>
              </Box>
            }

            <Divider sx={{margin:'10px auto', width:'100%'}} variant="middle" /> 

            <Box className="featureInfoAirText"> 
              <div className='featureInfoAirText'> 
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <div><i>soil formation : </i><b>{data.info.data.formation}</b></div>
                  <div><i>stratum : </i><b>{data.info.data.stratum}</b></div>
                </Grid> 
                <Grid item xs={4}>
                  <div><i>soil compaction : </i><b>{data.info.data.compaction}</b></div>
                  <div><i>boundary change : </i><b>{data.info.data.boundarychange}</b></div> 
                </Grid>
              </Grid>                  
                  
                  {/* compaction, boundarychange, formation, stratum */} 
              </div>

              <Divider sx={{margin:'10px auto', width:'100%'}} variant="middle" /> 

              <Box className="featureInfoAirText"> 
                <div><i>excavated at : </i> <b>{data.info.data.excdate} </b><i> using : </i> <b>{data.info.data.method}</b></div>
                {/* excdate method */}
                <div><i>excavators : </i><b>
                  {data.info.data.excavators.map((e,i)=>( 
                    data.info.data.excavators.length>1?
                    <span key={'excavators_'+i} >,{e}</span> 
                    :
                    <span key={'excavators_'+i} >{e}</span>
                  ))}</b>
                </div>

                {/* excavators */}
              </Box>

            </Box>  
        </div>
        </Box> 
      </TabPanel>


      <TabPanel value={valueTab} index={1}> 
      {/* relationships */}
      { stratigraphy===false ?
        <Box>    
          <div className="featureInfoLongestText"> 
            <Box sx={{width:'100%', display:'inline-block', textAlign:'center'}}>
              <i>Stratigraphic sequence</i> 
            </Box> 

            {data.info.data.relationships.length>0?
              <div>
                <div style={{width:'100%', padding:'5px', margin:'10px 0', border:'1px solid darkgray'}} >
                  <p>Contexts above Context No. {data.info.data.no} </p>
                  {data.info.data.relationshipsAbove.length>0?
                  <span>
                    {data.info.data.relationshipsAbove.map((e,i)=>( 
                      <span key={'relationships_'+i} className='featureInfoTextAir'>
                        <Button variant="outlined" sx={{margin:'5px'}} onMouseEnter={()=>setClickStateFeaturesPartInfo(e.context_id_related)} onMouseLeave={()=>unSetClickStateFeaturesPartInfo(e.context_id_related)} onClick={()=>clickFeatureFromListPartInfo(e.context_id_related, data.info.data.no)}  >{e.related_context}</Button>
                      </span>
                    ))}
                  </span>
                  :
                  <span>No contexts above recorded</span>
                  }
                </div>
                
                <div style={{width:'100%', padding:'5px', margin:'10px 0', border:'1px solid darkgray', backgroundColor:'lightgrey'}}>
                  <p>Contexts at the same level as Context No. {data.info.data.no}</p>    
                  <Button disabled variant="outlined" sx={{margin:'5px'}} >{data.info.data.no}</Button>
                  {data.info.data.relationshipsSame.length>0?
                  <span>
                    {data.info.data.relationshipsSame.map((e,i)=>( 
                      <span key={'relationships_'+i} className='featureInfoTextAir'>
                        <Button variant="outlined" sx={{margin:'5px'}} onMouseEnter={()=>setClickStateFeaturesPartInfo(e.context_id_related)} onMouseLeave={()=>unSetClickStateFeaturesPartInfo(e.context_id_related)} onClick={()=>clickFeatureFromListPartInfo(e.context_id_related, data.info.data.no)}  >{e.related_context}</Button>
                      </span>
                    ))}
                  </span>
                  :
                  <span>No other contexts recorded</span>
                  }        
                </div>
                
                
                <div style={{width:'100%', padding:'5px', margin:'10px 0', border:'1px solid darkgray'}}>
                  <p>Contexts below Context No. {data.info.data.no} </p>
                  {data.info.data.relationshipsBelow.length>0?
                  <span>
                    {data.info.data.relationshipsBelow.map((e,i)=>( 
                      <span key={'relationships_'+i} className='featureInfoTextAir'>
                        <Button variant="outlined" sx={{margin:'5px'}} onMouseEnter={()=>setClickStateFeaturesPartInfo(e.context_id_related)} onMouseLeave={()=>unSetClickStateFeaturesPartInfo(e.context_id_related)} onClick={()=>clickFeatureFromListPartInfo(e.context_id_related, data.info.data.no)}  >{e.related_context}</Button>
                      </span>
                    ))}
                  </span>
                  :
                  <span>No contexts below recorded</span>
                  }     
                </div>

                {
                data.info.data.relationships.length>0 &&      
                <Button variant="outlined"  style={{textTransform: 'none'}}  sx={{ textAlign: 'center', margin: '0 auto', display:'block' }} onClick={()=>prepareExtrudeStratigraphicContexts()}>
                  {extrudeAllClicked ? 'Remove All' : 'Expand All'}
                </Button>
                }

              </div>    
              :
              'No stratigraphical relations recorded'
            }  

          </div>
        </Box> 
        :
        <div style={{textAlign:'center', margin:'60px 0'}}>
          Part of 
          {stratigraphyType==='pa'?
          <span> {stratigraphyName} Stratigraphy</span>
          :
          <span> Context {stratigraphyName} Stratigraphy</span>
          }

        </div>
      }
      </TabPanel>


      <TabPanel value={valueTab} index={2}>
        {/* pottery */}
        <div className="featureInfoLongestText">
          <Box>     
            <div className='featureInfoTextAir'>
              <Box sx={{width:'100%', display:'inline-block', textAlign:'center'}}>
                <i>Pottery dating : </i><b>{data.info.data.pottery_datedesc}</b>
              </Box>
            </div> 
            <div className="featureInfoLongText">   
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div style={{textAlign:'center'}}><b>Pottery Weight</b></div>
                {weightChart.data === false?
                  <Box sx={{width:'100%', display:'inline-block', textAlign:'center', paddingTop:'10px'}}>
                    No sufficient data
                  </Box>
                  : 
                  <span>  
                    <Chart options={weightChart.data.options} series={weightChart.data.series} type="pie" width={320} height={320} />
                    <div className='weightCountSum'>{weightChart.sum} kg</div>
                  </span>
                }
              </Grid>
              <Grid item xs={6}>
              <div style={{textAlign:'center'}}><b>Pottery Count</b></div>
              {countChart.data === false?
                <Box sx={{width:'100%', display:'inline-block', textAlign:'center', paddingTop:'10px'}}>
                  No sufficient data
                </Box>
                : 
                <span> 
                  <Chart options={countChart.data.options} series={countChart.data.series} type="pie" width={320} height={320} />
                  <div className='weightCountSum'>{countChart.sum} pieces</div>
                </span>
              }
              </Grid> 
            </Grid> 

            </div>
            {/* pottery_datedesc */} 
            <small style={{textAlign:'center', display:'block', margin:'0 auto 5px auto'}}> 1)*for the number of sherds we sum the number of rims, handles, body sherds and bases </small> 

            <div className='featureInfoSubTitle'>Fine Ceramics</div> 
            <div className="featureInfoLongText">  
              {data.info.data.pottery.fine.length>0?    
                data.info.data.pottery.fine.map((e,i)=>( 
                  <div key={'fine_'+i} className='featureInfoTextAir'>
                    <div>
                      <b>{e.shape}</b> <i> , number of fragments : </i><b>{e.addsums}</b>
                    </div>   
                    <div>
                      <span><i>type : </i>{e.pot}</span>
                    </div> 
                    <Divider sx={{margin:'10px auto', width:'100%'}} variant="middle" />  
                  </div>
                ))
                :
                'No fine ceramics recorded'
              } 
            </div> 
            <div className='featureInfoSubTitle'>Coarse Ceramics</div> 
            <div className="featureInfoLongText"> 
              {data.info.data.pottery.coarse.length>0?    
                data.info.data.pottery.coarse.map((e,i)=>( 
                  <div key={'coarse_'+i} className='featureInfoTextAir'>
                    <div>
                      <b>{e.shape}</b> <i> , number of fragments : </i><b>{e.addsums}</b>
                    </div>   
                    <div>
                      <span><i>type : </i>{e.pot}</span>
                    </div> 
                    <Divider sx={{margin:'10px auto', width:'100%'}} variant="middle" />  
                  </div>
                ))
                :
                'No coarse ceramics recorded'
              } 
            </div>
            <div className='featureInfoSubTitle'>Cooking Ceramics</div>
            <div className="featureInfoLongText"> 
              {data.info.data.pottery.cooking.length>0?    
                data.info.data.pottery.cooking.map((e,i)=>( 
                  <div key={'cooking_'+i} className='featureInfoTextAir'>
                    <div>
                      <b>{e.shape}</b> <i> , number of fragments : </i><b>{e.addsums}</b>
                    </div>   
                    <div>
                      <span><i>type : </i> {e.pot}</span>
                    </div> 
                    <Divider sx={{margin:'10px auto', width:'100%'}} variant="middle" />  
                  </div>
                ))
                :
                'No cooking ceramics recorded'
              } 
            </div>   
          </Box>     
        </div>  
      </TabPanel> 

      <TabPanel value={valueTab} index={3}>
        {/* inventory */}
        <Box>     
          <div className="featureInfoLongestText">
          
            {data.info.data.inventory.length>0?        
              data.info.data.inventory.map((e,i)=>( 
                <div key={'inventory_'+i} className="featureInfoTextAir">
                  <div className='featureInfoSubTitle'>{e.objhead}  [{e.obj}_{e.year}_{e.no}]  </div> 
                  <i>date : </i>{e.date} 
                  <div >
                    <div className="featureInfoAirText"><i>material : </i>{e.material}</div>
                    <div className="featureInfoAirText"><i>condition : </i>{e.cond}</div>
                  </div>
                  <div className="featureInfoAirText">
                    <i>description : </i>{e.desc_original}
                  </div>
                  <div className="featureInfoAirText"><i>decor : </i>{e.decor}</div>
                  <i>weight : </i>{e.wt} 
                  <Divider sx={{margin:'10px auto', width:'100%'}} variant="middle" />  
                </div> 
              ))
            :
              'No inventory found'
            } 
            </div>
                {/* inventory.objhead 
                inventory.date
                inventory.material
                inventory.cond
                inventory.desc_original
                inventory.decor 
                inventory.wt */}
        </Box>     
      </TabPanel>  

      <TabPanel value={valueTab} index={4}>
        {/* other findings */}
        <Box>     
          <div className="featureInfoLongestText">


          {data.info.data.otherfindings.length>0?        
            data.info.data.otherfindings.map((e,i)=>( 
              <div key={'other_'+i} className="featureInfoTextAir">
                <div className="featureInfoAirText">
                  {e.other}
                </div>
                <Divider sx={{margin:'10px auto', width:'100%'}} variant="middle" />  
              </div> 
            ))
            :
            'No other findings found'
          }


 

          </div> 
        </Box>     
      </TabPanel>     

      <TabPanel value={valueTab} index={5}>
        {/* coins */}
        <Box>     
          <div className="featureInfoLongestText">


          {data.info.data.coins.length>0?        
            data.info.data.coins.map((e,i)=>( 
              <div key={'coins_'+i} className="featureInfoTextAir">
                <div className="featureInfoAirText">  
                  <div>
                    <i>coin number : </i><b>{e.coin_no!=='' && e.coin_no!==null && e.coin_no!=='null'? e.coin_no : ' - ' }</b>  , <i>issuing authority : </i><span sx={{textTransform:'capitilize'}} ><b>{e.issuing_authority!=='' && e.issuing_authority!==null && e.issuing_authority!=='null'? e.issuing_authority : ' - ' }</b></span>  
                  </div>
                    <i>chronology : </i><b><span style={{  textTransform: 'uppercase'}}>{e.chronology!=='' && e.chronology!==null && e.chronology!=='null'? e.chronology : ' - ' }</span></b>
                </div>
                <Divider sx={{margin:'10px auto', width:'100%'}} variant="middle" />  
              </div> 
            ))
            :
            'No coins found'
          }


 

          </div> 
        </Box>     
      </TabPanel>    

      <TabPanel value={valueTab} index={6}>
        {/* zooarch */}
        <div >
          <Box>     
            <div className='featureInfoTextAir'>
              <Box sx={{width:'100%', display:'inline-block', textAlign:'center'}}>
                <b>Number of Identifiable Specimens</b>
              </Box>
            </div> 
            <div >   
              <Grid container spacing={1} >
                <Grid item xs={7}> 
                  <div style={{textAlign:'center'}}><b>Specimens Count</b></div> 
                 
                  {zooChartApex.data === false || zooChartApex.data.length === 0 ?
                    <Box sx={{width:'100%', display:'inline-block', textAlign:'center', paddingTop:'10px'}}>
                      No sufficient data
                    </Box>
                    :
                    <span id='zoochart'>  

                      {data.info.data.zoo.chart.sum !== 0 ? 
                      
                        <Chart options={zooChartApex.data.options} series={zooChartApex.data.series} type="pie" width={400} height={400} /> 
                        :
                        ''
                      }


                      <div className='zooCountSum'>{zooChartApex.sum} specimens</div>
                    </span>                  
                  }

                </Grid>
                <Grid item xs={5} sx={{height:'320px', overflowY:'hidden'}}>
                <div style={{textAlign:'center'}}><b>Legend</b></div> 
                  <Box sx={{width:'100%', display:'inline-block', textAlign:'center', paddingTop:'10px', height:'310px', overflowY:'scroll'}}>
                      <List > 
                        {data.info.data.zoo.legend.map((e,i)=>( 
                          <ListItem key={'list_legend_'+i} > 
                            <small> {e} </small>
                          </ListItem>  
                        ))}
                      </List>
                  </Box> 
                </Grid> 
              </Grid>  
            </div> 

            <div className='featureInfoSubTitle'>Other Specimens</div> 
            <div className="featureInfoLongText">  
              <List> 
                {data.info.data.zoo.other.map((e,i)=>( 
                  <ListItem key={'othersp_'+i}   >
                    <div>{e}</div>  
                  </ListItem>  
                ))}
              </List>
            </div>  
          </Box>     
        </div>  
      </TabPanel> 

      <TabPanel value={valueTab} index={7}>
        {/* botany */}
        <Box>     
          <div className="featureInfoLongestText">


          {data.info.data.botany.length>0?        
            data.info.data.botany.map((e,i)=>( 
              <div key={'coins_'+i} className="featureInfoTextAir">
                <div className="featureInfoAirText">  
                  <div>
                    <i>aggregation of botany findings : </i><b>{e.botany!=='' && e.botany!==null && e.botany!=='null'? e.botany : ' - ' }</b>
                  </div> 
                </div>
                <Divider sx={{margin:'10px auto', width:'100%'}} variant="middle" />  
              </div> 
            ))
            :
            'No floaral remains found'
          }


 

          </div> 
        </Box>     
      </TabPanel>  
                                              

    </div>
  )
}

export default ContextInfo



