import { useEffect } from 'react';      
import Typography from '@mui/material/Typography'; 
import Grid from '@mui/material/Grid'; 
import Paper from '@mui/material/Paper'; 
import Box from '@mui/material/Box'; 
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const About = () => {
  useEffect(() => {
    document.title = 'About , Sikyon excavation';
  }, []);
  return (
    <div className='general-container' style={{height:'100%'}}>
      <Typography component='div' variant='h4' className='aboutheader' >About Sikyon Project</Typography>  
      <Grid container spacing={2} id='aboutcontainer' >
        <Grid item xs={12}>
          <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}>
            <Typography component='div' variant='h5' className='aboutheader' >The excavation</Typography> 
            <Box>
              Τhe first round of the recent excavations at Sikyon (2013-2019), conducted under the auspices of the Archaeological Society of Athens, targeted two distinct areas to the northwest and the southeast of the agora, in an effort to gain a picture, as representative as possible, of the past activities around the agora. The selection of these areas was based on the results of the surface and geophysical surveys, carried out between 2004 and 2009. The total surface area of the excavated trenches comes to approximately 2,000 m2.
            </Box>
          </Paper>
        </Grid> 

        <Grid item xs={12}>
          <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}>
            <Typography component='div' variant='h5' className='aboutheader' >Scientific Supervision</Typography> 
            <Box >
              <List >
                <ListItem sx={{textAlign:'center',margin:'0 auto', width:'100%', display:'block'}}>Yannis Lolos (University of Thessaly)</ListItem>
                <ListItem sx={{textAlign:'center',margin:'0 auto', width:'100%', display:'block'}}>Scott Gallimore (Wilfrid Laurier University, Canada)</ListItem>
                <ListItem sx={{textAlign:'center',margin:'0 auto', width:'100%', display:'block'}}>Sarah James (University of Colorado, Boulder, USA)</ListItem>
                <ListItem sx={{textAlign:'center',margin:'0 auto', width:'100%', display:'block'}}>Marty Wells (Austin College, USA)</ListItem>
              </List>
            </Box>
          </Paper>
        </Grid> 

        <Grid item xs={12}>
          <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}>
            <Typography component='div' variant='h5' className='aboutheader' >Research Team</Typography> 
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  <List > 
                    <ListItem >Yannis Lolos (University of Thessaly): direction of the project</ListItem>
                    <ListItem >Dimitris Bartzis (Polytechnic School, Athens): photogrammetry, architectural analysis</ListItem>
                    <ListItem >Kate Behan (University of Colorado, Boulder, USA): registration of finds</ListItem>
                    <ListItem >DIPYLON, Society for the Study of Ancient Topography: digital mapping</ListItem>
                    <ListItem >Scott Gallimore (Wilfrid Laurier University, Canada): direction of the registration of finds and study of the Roman ceramics</ListItem>
                    <ListItem >Myrsini Gkouma (Wiener Lab, American School of Classical Studies): micromorphological analysis</ListItem>
                    <ListItem >Alexandros Gounaris (University of Thessaly): architectural drawing</ListItem>
                    <ListItem >Sarah James (University of Colorado, Boulder, USA): study of the Hellenistic ceramics</ListItem>
                    <ListItem >Vangelio Kyriatzi (Fitch Lab, British School at Athens): ceramic analyses</ListItem>
                  </List>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <ListItem >Vaitsa Makresia (University of Thessaly): architectural drawing</ListItem>
                  <ListItem >Irini Marathaki (German Archaeological Institute at Athens): study of the coins</ListItem>
                  <ListItem >Evi Margaritis (The Cyprus Institute): archaeobotanical analysis</ListItem>
                  <ListItem >Nicola Nenci (University of Perugia, Italy): study of the excavated trenches</ListItem>
                  <ListItem >Alice Ognier (Université de Bordeaux-Montaigne, France): pottery drawing</ListItem>
                  <ListItem >Amalia Siatou: conservation of finds and of excavated areas</ListItem>
                  <ListItem >Chryssa Varela (University of Thessaly): preparation of the ceramic reference collection and of the thematic exhibition</ListItem>
                  <ListItem >Marty Wells (Austin College, USA): field director</ListItem>
                  <ListItem >Eftychia Yannouli (independent scholar): study of the faunal remains</ListItem> 
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid> 

      </Grid> 
    </div>
  )
}

export default About
