import { useEffect } from 'react';      
import Typography from '@mui/material/Typography'; 
import Grid from '@mui/material/Grid'; 
import Paper from '@mui/material/Paper'; 
import Box from '@mui/material/Box'; 

const Terms = () => {
  useEffect(() => {
    document.title = 'Terms Of Use , Sikyon excavation';
  }, []);
  return (
    <div className='general-container' style={{height:'100%'}}>
      <Typography component='div' variant='h4' className='aboutheader' >Terms of use</Typography> 

      <div > 
        <Grid container spacing={2} id='aboutcontainer' >
          <Grid item xs={12}>
            <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}>
              <Typography component='div' variant='h5' className='aboutheader' >Permission & Access</Typography> 
              <Box>
                Every visitor to the website sikyonexcavation.gr (henceforth “the website”) has a non-exclusive, non-transferable, personal, limited right of access, use and presentation of this website and its contents under the terms and conditions set forth herein and in accordance with current applicable Greek and Community legislation. The visiting and use of the site requires the unconditional acceptance of these terms and is subject to the following restrictions: The user must preserve all copyright notices and other proprietary rights in all reproductions of the website and its data. The user may not modify the website or its data in any way or reproduce or publicly display or distribute or otherwise use the website and its data for any public or commercial purpose, unless otherwise permitted by Dipylon.
              </Box>
            </Paper>
          </Grid> 

          <Grid item xs={12}>
            <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}>
              <Typography component='div' variant='h5' className='aboutheader' >Changes</Typography> 
              <Box>
                Dipylon reserves the right to alter or amend the applicable terms and conditions for the use of the site at any time. Such changes, modifications, additions or deletions to the terms and conditions of use will come into force directly from the posting of the new terms. Any use of the site after such a change or modification will be deemed to constitute acceptance by the user of such changes, modifications, additions or deletions. Dipylon may, at any time, terminate, change, suspend or discontinue any particular function of this site.
              </Box>
            </Paper>
          </Grid> 

          <Grid item xs={12}>
            <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}>
              <Typography component='div' variant='h5' className='aboutheader' >Limitation of Liability</Typography> 
              <Box>
                Dipylon, its employees and other representatives shall have no liability under any circumstances whatsoever for any consequential, incidental, indirect, or specific indemnities or costs or financial penalties, including, but not limited to, lost profits, business discontinuance, loss of information or data, or loss of customers, loss or damage to property, nor any third party claims arising out of or in connection with the use, copying, or disclosure of this site or its contents, nor of any other linked site. Dipylon bears no responsibility for the accuracy, content and availability of the information that is accessible through the use of its website. Dipylon does not guarantee that the website or any other related site (available via its links) or the servers through which they are made available to users does not contain “viruses” or other harmful components.
              </Box>
            </Paper>
          </Grid> 

          <Grid item xs={12}>
            <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}>
              <Typography component='div' variant='h5' className='aboutheader' >Intellectual Property Rights and Signal</Typography> 
              <Box>
                All site design, text, graphics, and configurations are the property of Dipylon and are protected under copyright laws. Each trademark appearing on this site is registered and protected by the applicable copyright law and is used here with the permission of their respective owners, which are granted exclusively to Dipylon and do not extend to the users of the site. In the event that the user chooses to retrieve information and data from the website for the writing of a work, article or other intellectual piece which they intend in any way to make public or to publish, they have the obligation to ensure, prior to publication, the written concession of copyright related to the information or item that they intend to use.
              </Box>
            </Paper>
          </Grid> 

          <Grid item xs={12}>
            <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}>
              <Typography component='div' variant='h5' className='aboutheader' >User Personal Data</Typography> 
              <Box>
                Dipylon may collect personal user data through the site. Dipylon uses this personal data solely for the user’s support and for the selection of the content offered in order to make it more relevant to their general preferences. Dipylon does not have the right to disclose this data to third parties unless otherwise specified by the law. The user reserves the right to request at any time the deletion of their personal data by sending an email to Dipylon.
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}>
              <Typography component='div' variant='h5' className='aboutheader' >Applicable Law and Other Terms</Typography> 
              <Box>
                The above terms and conditions of use of the website, as well as any modification thereof, are governed by and supplemented by Greek and Community law. If any provision of these terms becomes legally inapplicable, it shall cease to be valid and shall be withdrawn from the website, without in any way undermining the validity of the other terms. Any dispute arising between Dipylon and the user of the website comes under the exclusive jurisdiction of the Courts of Athens.
              </Box>
            </Paper>
          </Grid> 

        </Grid> 
      </div>
      
    </div>
  )
}

export default Terms
