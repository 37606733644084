import { useEffect } from 'react';      
import Typography from '@mui/material/Typography'; 
import Grid from '@mui/material/Grid'; 
import Paper from '@mui/material/Paper'; 
import Box from '@mui/material/Box'; 

const Policy = () => {
  useEffect(() => {
    document.title = 'Cookies Policy , Sikyon excavation';
  }, []);
  return (
    <div className='page-container'>
      <Typography component='div' variant='h4' className='aboutheader' >Cookies Policy</Typography> 

      <Grid container spacing={2} id='aboutcontainer' >
        <Grid item xs={12}>
          <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}> 
            <Box>
              While browsing this site, we may collect user’s identification data, by using appropriate technologies, such as cookies and / or Internet Protocol (IP) address tracking. Cookies are small pieces of text that are sent to the browser by the website that the user visits. The use of cookies facilitates the website to memorize information about the user's visit, such as your preferred language, your preferences in terms of secure search, the number of visitors or the facilitation of your registration to our services.
            </Box>
          </Paper>
        </Grid> 

        <Grid item xs={12}>
          <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}> 
            <Box>
              The website for the Sikyon excavation may use third party cookies in addition to its own cookies. They are collected for the purpose of analyzing the data of our visitors, promoting our project or ensuring the quality and safety of your browsing. They are kept in our files for a short period of time, in any case less than a year.
            </Box>
          </Paper>
        </Grid> 

        <Grid item xs={12}>
          <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}> 
            <Box>
              All cookies collected are kept anonymous and are not directly linked to the user. The company undertakes the obligation not to sell or promote the information collected through cookies.
            </Box>
          </Paper>
        </Grid> 

        <Grid item xs={12}>
          <Paper elevation={2} sx={{margin:'10px', padding:'20px'}}> 
            <Box>
            How to control cookies: You can control and / or delete cookies according to your wishes. You can delete all cookies that are already on your computer, as well as configure most browsers to prevent the installation of cookies. However, in this case, you may need to adjust some preferences yourself each time you visit a site. The user can use the website without problems and without the use of cookies, accept the ones necessary for the proper operation of the website. However this action may decrease its usability and the operation of certain services.

            Any personal data collected through cookies and associated with a specific visitor / user are subject to processing exclusively for the purposes mentioned above.
            </Box>
          </Paper>
        </Grid> 

      </Grid>
      
    </div>
  )
}

export default Policy
